import { AnalysisResult, QAAnalysisResult } from "./interfaces"
import { AreaType, CarParkingType, LeaseType, LiabilityType, UnitCondition, YesNo } from "../lib/enums";

export const testData: QAAnalysisResult = {
    "documentNumber": "25249",
    "projectName": "Altimus",
    "plotNumber": "130",
    "district": "Mumbai",
    "village": "Worli",
    "khewat": null,
    "municipality": null,
    "registrarOffice": "Mumbai City 2",
    "agreementType": LeaseType.LEAVE_AND_LICENSE,
    "registrationDate": "10-24-2024",
    "landlord": "Whispering Heights Real Estate Private Limited",
    "landlordRep": "Devashish Gupta",
    "landlordRepRole": "Authorized Signatory",
    "tenant": "KKR India Advisors Private Limited",
    "tenantRep": "Farida Karanjia",
    "tenantRepRole": "Authorized Signatory",
    "unit": "4101",
    "floor": "41",
    "wing": null,
    "addressExtraDiscriminator": null,
    "superBuiltupArea": null,
    "chargeableAreaType": AreaType.BUILTUP,
    "builtUpArea": 42700,
    "carpetArea": 25620,
    "agreementStartDate": "10-01-2024",
    "agreementExpirationDate": null,
    "agreementDuration": 120,
    "rentMonthly": 14945000,
    "rentPerSquareFootMonthly": 350,
    "considerationValue": 14945000,
    "escalationTerms": null,
    "unitCondition": UnitCondition.WARMSHELL,
    "unitWithFitOuts": YesNo.YES,
    "furnishedRate": YesNo.NO,
    "rentFreePeriod": 6,
    "landlordLockIn": 120,
    "tenantLockIn": 60,
    "securityDeposit": 179340000,
    "securityDepositEquivalent": 12,
    "marketValue": null,
    "stampDutyAmount": 17767000,
    "registrationAmount": 30000,
    "carParkingSlots": 32,
    "carParkingType": CarParkingType.COVERED,
    "carParkingCharge": null,
    "twoWheelerSlots": null,
    "twoWheelerCharge": null,
    "monthlyCamCharge": 1096700,
    "camCharge": 25,
    "camPaidBy": LiabilityType.TENANT,
    "totalPropertyTax": null,
    "propertyTax": null,
    "propertyTaxPaidBy": LiabilityType.TENANT,
    "floorPlan": null,
    "reportSASURL": "string"
}

export const emptyResult: QAAnalysisResult = {
    "documentNumber": "",
    "projectName": "",
    "plotNumber": "",
    "district": "",
    "village": "",
    "khewat": "",
    "municipality": "",
    "registrarOffice": "",
    "agreementType": null,
    "registrationDate": "",
    "landlord": "",
    "landlordRep": "",
    "landlordRepRole": "",
    "tenant": "",
    "tenantRep": "",
    "tenantRepRole": "",
    "unit": "",
    "floor": "",
    "wing": "",
    "addressExtraDiscriminator": "",
    "chargeableAreaType": null,
    "superBuiltupArea": null,
    "builtUpArea": null,
    "carpetArea": null,
    "agreementStartDate": "",
    "agreementExpirationDate": "",
    "agreementDuration": null,
    "rentMonthly": null,
    "rentPerSquareFootMonthly": null,
    "considerationValue": null,
    "escalationTerms": "",
    "unitCondition": null,
    "unitWithFitOuts": null,
    "furnishedRate": null,
    "rentFreePeriod": null,
    "landlordLockIn": null,
    "tenantLockIn": null,
    "securityDeposit": null,
    "securityDepositEquivalent": null,
    "marketValue": null,
    "stampDutyAmount": null,
    "registrationAmount": null,
    "carParkingSlots": null,
    "carParkingType": null,
    "carParkingCharge": null,
    "twoWheelerSlots": null,
    "twoWheelerCharge": null,
    "monthlyCamCharge": null,
    "camCharge": null,
    "camPaidBy": null,
    "totalPropertyTax": null,
    "propertyTax": null,
    "propertyTaxPaidBy": null,
    "floorPlan": null,
    "reportSASURL": ""
}

export const data: AnalysisResult = {
    "documentNumber": {
        "value": "25249",
        "sources": [
            {
                "page_range": [
                    71,
                    71
                ],
                "bboxes": [
                    {
                        "left": 0.05481103510638467,
                        "top": 0.002129268551629687,
                        "width": 0.9029128442402758,
                        "height": 0.3450567140465691,
                        "page": 71,
                        "original_page": 71
                    },
                    {
                        "left": 0.08088235294117647,
                        "top": 0.37549603174603174,
                        "width": 0.1854575163398693,
                        "height": 0.07490079365079365,
                        "page": 71,
                        "original_page": 71
                    },
                    {
                        "left": 0.8643790849673203,
                        "top": 0.3695436507936508,
                        "width": 0.09477124183006536,
                        "height": 0.01984126984126984,
                        "page": 71,
                        "original_page": 71
                    },
                    {
                        "left": 0.7941176470588235,
                        "top": 0.39037698412698413,
                        "width": 0.18137254901960784,
                        "height": 0.043154761904761904,
                        "page": 71,
                        "original_page": 71
                    },
                    {
                        "left": 0.07679738562091504,
                        "top": 0.4751984126984127,
                        "width": 0.0024509803921568627,
                        "height": 0.010416666666666666,
                        "page": 71,
                        "original_page": 71
                    },
                    {
                        "left": 0.08251633986928104,
                        "top": 0.45287698412698413,
                        "width": 0.8611111111111112,
                        "height": 0.032242063492063495,
                        "page": 71,
                        "original_page": 71
                    },
                    {
                        "left": 0.08251633986928104,
                        "top": 0.49206349206349204,
                        "width": 0.41421568627450983,
                        "height": 0.01488095238095238,
                        "page": 71,
                        "original_page": 71
                    },
                    {
                        "left": 0.08251633986928104,
                        "top": 0.5138888888888888,
                        "width": 0.3660130718954248,
                        "height": 0.014384920634920634,
                        "page": 71,
                        "original_page": 71
                    },
                    {
                        "left": 0.4477124183006536,
                        "top": 0.5625,
                        "width": 0.119281045751634,
                        "height": 0.016865079365079364,
                        "page": 71,
                        "original_page": 71
                    },
                    {
                        "left": 0.31290849673202614,
                        "top": 0.5818452380952381,
                        "width": 0.4076797385620915,
                        "height": 0.07242063492063493,
                        "page": 71,
                        "original_page": 71
                    },
                    {
                        "left": 0.729278564453125,
                        "top": 0.5348166208418589,
                        "width": 0.22968127680759803,
                        "height": 0.13621236407567586,
                        "page": 71,
                        "original_page": 71
                    },
                    {
                        "left": 0.29901960784313725,
                        "top": 0.6572420634920635,
                        "width": 0.14787581699346405,
                        "height": 0.05555555555555555,
                        "page": 71,
                        "original_page": 71
                    },
                    {
                        "left": 0.6233660130718954,
                        "top": 0.6865079365079365,
                        "width": 0.10620915032679738,
                        "height": 0.015873015873015872,
                        "page": 71,
                        "original_page": 71
                    },
                    {
                        "left": 0.07516339869281045,
                        "top": 0.9226190476190477,
                        "width": 0.004901960784313725,
                        "height": 0.00496031746031746,
                        "page": 71,
                        "original_page": 71
                    }
                ]
            },
            {
                "page_range": [
                    72,
                    73
                ],
                "bboxes": [
                    {
                        "left": 0.923202614379085,
                        "top": 0.8015873015873016,
                        "width": 0.07352941176470588,
                        "height": 0.005456349206349206,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.4673202614379085,
                        "top": 0.8159722222222222,
                        "width": 0.14787581699346405,
                        "height": 0.005952380952380952,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.09722222222222222,
                        "top": 0.8268849206349206,
                        "width": 0.47630718954248363,
                        "height": 0.010416666666666666,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.09640522875816994,
                        "top": 0.8407738095238095,
                        "width": 0.1854575163398693,
                        "height": 0.00744047619047619,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.39950980392156865,
                        "top": 0.847718253968254,
                        "width": 0.2826797385620915,
                        "height": 0.008432539682539682,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.06345455942590253,
                        "top": 0.035385972280350945,
                        "width": 0.24202333088793787,
                        "height": 0.020585392543247767,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.06209150326797386,
                        "top": 0.057777777777777775,
                        "width": 0.14624183006535948,
                        "height": 0.007301587301587302,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.4526143790849673,
                        "top": 0.03841269841269841,
                        "width": 0.10702614379084967,
                        "height": 0.007301587301587302,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.6478758169934641,
                        "top": 0.03365079365079365,
                        "width": 0.3006535947712418,
                        "height": 0.026984126984126985,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.06454248366013073,
                        "top": 0.07777777777777778,
                        "width": 0.16258169934640523,
                        "height": 0.007936507936507936,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.06290849673202614,
                        "top": 0.09047619047619047,
                        "width": 0.12091503267973856,
                        "height": 0.009206349206349206,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.06862745098039216,
                        "top": 0.11777777777777777,
                        "width": 0.1854575163398693,
                        "height": 0.009523809523809525,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.5106209150326797,
                        "top": 0.11873015873015873,
                        "width": 0.08333333333333333,
                        "height": 0.005079365079365079,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.10130718954248366,
                        "top": 0.13174603174603175,
                        "width": 0.4542483660130719,
                        "height": 0.05238095238095238,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.5294117647058824,
                        "top": 0.16095238095238096,
                        "width": 0.0784313725490196,
                        "height": 0.023174603174603174,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.7034313725490197,
                        "top": 0.11555555555555555,
                        "width": 0.05310457516339869,
                        "height": 0.008253968253968255,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.8513071895424836,
                        "top": 0.11460317460317461,
                        "width": 0.06781045751633986,
                        "height": 0.009523809523809525,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.6387384452071845,
                        "top": 0.12103157164558531,
                        "width": 0.13302916483162275,
                        "height": 0.07191962348090278,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.8147793938131893,
                        "top": 0.12790081690228175,
                        "width": 0.09866218317567912,
                        "height": 0.0647418697296627,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.06209150326797386,
                        "top": 0.20666666666666667,
                        "width": 0.4534313725490196,
                        "height": 0.020317460317460317,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.061274509803921566,
                        "top": 0.24095238095238095,
                        "width": 0.5522875816993464,
                        "height": 0.019365079365079366,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.07026143790849673,
                        "top": 0.26571428571428574,
                        "width": 0.1568627450980392,
                        "height": 0.010158730158730159,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.08905228758169935,
                        "top": 0.28095238095238095,
                        "width": 0.15931372549019607,
                        "height": 0.040317460317460314,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.08986928104575163,
                        "top": 0.34793650793650793,
                        "width": 0.17565359477124182,
                        "height": 0.04,
                        "page": 73,
                        "original_page": 73
                    }
                ]
            },
            {
                "page_range": [
                    72,
                    72
                ],
                "bboxes": [
                    {
                        "left": 0.006208066067664452,
                        "top": 0.026974808602106003,
                        "width": 0.041749404146780376,
                        "height": 0.03824090768420507,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.06623201432570912,
                        "top": 0.03782172808571467,
                        "width": 0.24470645306157132,
                        "height": 0.02403812181381952,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.0661764705882353,
                        "top": 0.06101190476190476,
                        "width": 0.13970588235294118,
                        "height": 0.00992063492063492,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.06944444444444445,
                        "top": 0.0818452380952381,
                        "width": 0.16176470588235295,
                        "height": 0.023313492063492064,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.4550653594771242,
                        "top": 0.03720238095238095,
                        "width": 0.10457516339869281,
                        "height": 0.008928571428571428,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.6519607843137255,
                        "top": 0.026785714285714284,
                        "width": 0.30800653594771243,
                        "height": 0.03422619047619048,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.07352941176470588,
                        "top": 0.11706349206349206,
                        "width": 0.5261437908496732,
                        "height": 0.07787698412698413,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.6505442101970996,
                        "top": 0.11325803635612366,
                        "width": 0.13112934586269404,
                        "height": 0.07807432659088619,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.8266060025084252,
                        "top": 0.10966848948645214,
                        "width": 0.09829143449371937,
                        "height": 0.07943739209856306,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.06944444444444445,
                        "top": 0.20684523809523808,
                        "width": 0.4591503267973856,
                        "height": 0.01636904761904762,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.07352941176470588,
                        "top": 0.23660714285714285,
                        "width": 0.5547385620915033,
                        "height": 0.018849206349206348,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.07924836601307189,
                        "top": 0.2604166666666667,
                        "width": 0.16094771241830066,
                        "height": 0.011904761904761904,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.10049019607843138,
                        "top": 0.2748015873015873,
                        "width": 0.16094771241830066,
                        "height": 0.042162698412698416,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.10294117647058823,
                        "top": 0.3427579365079365,
                        "width": 0.1781045751633987,
                        "height": 0.03968253968253968,
                        "page": 72,
                        "original_page": 72
                    }
                ]
            },
            {
                "page_range": [
                    73,
                    73
                ],
                "bboxes": [
                    {
                        "left": 0.3684640522875817,
                        "top": 0.5555555555555556,
                        "width": 0.05228758169934641,
                        "height": 0.023809523809523808,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.05473856209150327,
                        "top": 0.5746031746031746,
                        "width": 0.9158496732026143,
                        "height": 0.2165079365079365,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.06699346405228758,
                        "top": 0.7923809523809524,
                        "width": 0.4673202614379085,
                        "height": 0.009206349206349206,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.06944444444444445,
                        "top": 0.8247619047619048,
                        "width": 0.5196078431372549,
                        "height": 0.028888888888888888,
                        "page": 73,
                        "original_page": 73
                    }
                ]
            }
        ]
    },
    "projectName": {
        "value": "Altimus",
        "sources": [
            {
                "page_range": [
                    2,
                    2
                ],
                "bboxes": [
                    {
                        "left": 0.2908496732026144,
                        "top": 0.09176587301587301,
                        "width": 0.42892156862745096,
                        "height": 0.015873015873015872,
                        "page": 2,
                        "original_page": 2
                    },
                    {
                        "left": 0.06290849673202614,
                        "top": 0.1130952380952381,
                        "width": 0.8766339869281046,
                        "height": 0.26884920634920634,
                        "page": 2,
                        "original_page": 2
                    }
                ]
            },
            {
                "page_range": [
                    5,
                    6
                ],
                "bboxes": [
                    {
                        "left": 0.17647058823529413,
                        "top": 0.7966269841269841,
                        "width": 0.636437908496732,
                        "height": 0.02628968253968254,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.15020111183715024,
                        "top": 0.8445979527064732,
                        "width": 0.13207651275435303,
                        "height": 0.08065529475136408,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.38132748572654973,
                        "top": 0.828873043968564,
                        "width": 0.2876784630071104,
                        "height": 0.11042325458829365,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.7604768042470894,
                        "top": 0.8320226517934648,
                        "width": 0.16923722410513684,
                        "height": 0.06536332387772817,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.2107843137254902,
                        "top": 0.09970238095238096,
                        "width": 0.5923202614379085,
                        "height": 0.04017857142857143,
                        "page": 6,
                        "original_page": 6
                    }
                ]
            },
            {
                "page_range": [
                    6,
                    6
                ],
                "bboxes": [
                    {
                        "left": 0.1650326797385621,
                        "top": 0.16170634920634921,
                        "width": 0.636437908496732,
                        "height": 0.2013888888888889,
                        "page": 6,
                        "original_page": 6
                    }
                ]
            },
            {
                "page_range": [
                    35,
                    37
                ],
                "bboxes": [
                    {
                        "left": 0.38235294117647056,
                        "top": 0.07688492063492064,
                        "width": 0.23366013071895425,
                        "height": 0.024305555555555556,
                        "page": 35,
                        "original_page": 35
                    },
                    {
                        "left": 0.20745915680928947,
                        "top": 0.1802066924080016,
                        "width": 0.5844675076553245,
                        "height": 0.3813768114362444,
                        "page": 35,
                        "original_page": 35
                    },
                    {
                        "left": 0.20506535947712418,
                        "top": 0.5625,
                        "width": 0.1830065359477124,
                        "height": 0.004464285714285714,
                        "page": 35,
                        "original_page": 35
                    },
                    {
                        "left": 0.2034313725490196,
                        "top": 0.5749007936507936,
                        "width": 0.056372549019607844,
                        "height": 0.00496031746031746,
                        "page": 35,
                        "original_page": 35
                    },
                    {
                        "left": 0.2034313725490196,
                        "top": 0.5818452380952381,
                        "width": 0.38235294117647056,
                        "height": 0.010416666666666666,
                        "page": 35,
                        "original_page": 35
                    },
                    {
                        "left": 0.3668876847410514,
                        "top": 0.5975355118040054,
                        "width": 0.22792334961735344,
                        "height": 0.13573570856972347,
                        "page": 35,
                        "original_page": 35
                    },
                    {
                        "left": 0.6219095965616064,
                        "top": 0.5953616793193515,
                        "width": 0.29161006952422897,
                        "height": 0.11660197424510169,
                        "page": 35,
                        "original_page": 35
                    },
                    {
                        "left": 0.18520811492321537,
                        "top": 0.7342657664465526,
                        "width": 0.1318614186804279,
                        "height": 0.08205758957635789,
                        "page": 35,
                        "original_page": 35
                    },
                    {
                        "left": 0.5949306612700419,
                        "top": 0.7284373328799293,
                        "width": 0.10721239077499489,
                        "height": 0.06572360084170387,
                        "page": 35,
                        "original_page": 35
                    },
                    {
                        "left": 0.4869281045751634,
                        "top": 0.8784722222222222,
                        "width": 0.015522875816993464,
                        "height": 0.005952380952380952,
                        "page": 35,
                        "original_page": 35
                    },
                    {
                        "left": 0.3553921568627451,
                        "top": 0.10317460317460317,
                        "width": 0.22712418300653595,
                        "height": 0.024305555555555556,
                        "page": 36,
                        "original_page": 36
                    },
                    {
                        "left": 0.19999893038880592,
                        "top": 0.22436040545266772,
                        "width": 0.49713096119999106,
                        "height": 0.35415611569843597,
                        "page": 36,
                        "original_page": 36
                    },
                    {
                        "left": 0.11332529354718776,
                        "top": 0.5889329456147694,
                        "width": 0.226616042891359,
                        "height": 0.13722677079458084,
                        "page": 36,
                        "original_page": 36
                    },
                    {
                        "left": 0.3235294117647059,
                        "top": 0.6125992063492064,
                        "width": 0.08006535947712418,
                        "height": 0.005952380952380952,
                        "page": 36,
                        "original_page": 36
                    },
                    {
                        "left": 0.6094771241830066,
                        "top": 0.6016865079365079,
                        "width": 0.004901960784313725,
                        "height": 0.00248015873015873,
                        "page": 36,
                        "original_page": 36
                    },
                    {
                        "left": 0.7249686047921773,
                        "top": 0.578788091265966,
                        "width": 0.03988213632621017,
                        "height": 0.03690392630440848,
                        "page": 36,
                        "original_page": 36
                    },
                    {
                        "left": 0.31699346405228757,
                        "top": 0.6264880952380952,
                        "width": 0.24591503267973855,
                        "height": 0.010912698412698412,
                        "page": 36,
                        "original_page": 36
                    },
                    {
                        "left": 0.11138502133438011,
                        "top": 0.7484103006029886,
                        "width": 0.30188175276214,
                        "height": 0.2101566375248016,
                        "page": 36,
                        "original_page": 36
                    },
                    {
                        "left": 0.4982251435323478,
                        "top": 0.7519014752100385,
                        "width": 0.18543587478936888,
                        "height": 0.06667140173533606,
                        "page": 36,
                        "original_page": 36
                    },
                    {
                        "left": 0.46405228758169936,
                        "top": 0.9032738095238095,
                        "width": 0.016339869281045753,
                        "height": 0.005952380952380952,
                        "page": 36,
                        "original_page": 36
                    },
                    {
                        "left": 0.4452614379084967,
                        "top": 0.07589285714285714,
                        "width": 0.08823529411764706,
                        "height": 0.008432539682539682,
                        "page": 37,
                        "original_page": 37
                    },
                    {
                        "left": 0.190359477124183,
                        "top": 0.10813492063492064,
                        "width": 0.5996732026143791,
                        "height": 0.03968253968253968,
                        "page": 37,
                        "original_page": 37
                    },
                    {
                        "left": 0.17401960784313725,
                        "top": 0.15376984126984128,
                        "width": 0.3741830065359477,
                        "height": 0.013392857142857142,
                        "page": 37,
                        "original_page": 37
                    }
                ]
            },
            {
                "page_range": [
                    60,
                    60
                ],
                "bboxes": [
                    {
                        "left": 0.415522481880936,
                        "top": 0.05622972003997318,
                        "width": 0.13560019599066842,
                        "height": 0.080908873724559,
                        "page": 60,
                        "original_page": 60
                    },
                    {
                        "left": 0.3349673202614379,
                        "top": 0.14186507936507936,
                        "width": 0.2973856209150327,
                        "height": 0.016865079365079364,
                        "page": 60,
                        "original_page": 60
                    },
                    {
                        "left": 0.13970588235294118,
                        "top": 0.1636904761904762,
                        "width": 0.690359477124183,
                        "height": 0.026785714285714284,
                        "page": 60,
                        "original_page": 60
                    },
                    {
                        "left": 0.09395424836601307,
                        "top": 0.20436507936507936,
                        "width": 0.018790849673202614,
                        "height": 0.005952380952380952,
                        "page": 60,
                        "original_page": 60
                    },
                    {
                        "left": 0.09477124183006536,
                        "top": 0.2113095238095238,
                        "width": 0.47549019607843135,
                        "height": 0.01636904761904762,
                        "page": 60,
                        "original_page": 60
                    }
                ]
            },
            {
                "page_range": [
                    69,
                    70
                ],
                "bboxes": [
                    {
                        "left": 0.1919934640522876,
                        "top": 0.21626984126984128,
                        "width": 0.5808823529411765,
                        "height": 0.07886904761904762,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.059640522875817,
                        "top": 0.2986111111111111,
                        "width": 0.6862745098039216,
                        "height": 0.09375,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.036518368066525926,
                        "top": 0.40847911531963044,
                        "width": 0.2542090104296317,
                        "height": 0.11437564425998265,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.05310457516339869,
                        "top": 0.5287698412698413,
                        "width": 0.8978758169934641,
                        "height": 0.052083333333333336,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.3214109333512051,
                        "top": 0.6635828775072855,
                        "width": 0.2280039569131689,
                        "height": 0.13616016932896205,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.582526462529999,
                        "top": 0.6644446357848153,
                        "width": 0.28015141705282376,
                        "height": 0.10952661907862103,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.31731923421223956,
                        "top": 0.19365333375476657,
                        "width": 0.06492467643388736,
                        "height": 0.03634834289550781,
                        "page": 70,
                        "original_page": 70
                    },
                    {
                        "left": 0.3872549019607843,
                        "top": 0.19345238095238096,
                        "width": 0.31290849673202614,
                        "height": 0.03720238095238095,
                        "page": 70,
                        "original_page": 70
                    }
                ]
            }
        ]
    },
    "plotNumber": {
        "value": "Survey No. 1618, Plot No. 130",
        "sources": [
            {
                "page_range": [
                    1,
                    1
                ],
                "bboxes": [
                    {
                        "left": 0.05065359477124183,
                        "top": 0.03373015873015873,
                        "width": 0.9076797385620915,
                        "height": 0.6011904761904762,
                        "page": 1,
                        "original_page": 1
                    }
                ]
            },
            {
                "page_range": [
                    2,
                    2
                ],
                "bboxes": [
                    {
                        "left": 0.2908496732026144,
                        "top": 0.09176587301587301,
                        "width": 0.42892156862745096,
                        "height": 0.015873015873015872,
                        "page": 2,
                        "original_page": 2
                    },
                    {
                        "left": 0.06290849673202614,
                        "top": 0.1130952380952381,
                        "width": 0.8766339869281046,
                        "height": 0.26884920634920634,
                        "page": 2,
                        "original_page": 2
                    }
                ]
            },
            {
                "page_range": [
                    3,
                    3
                ],
                "bboxes": [
                    {
                        "left": 0.28022875816993464,
                        "top": 0.08928571428571429,
                        "width": 0.42892156862745096,
                        "height": 0.01984126984126984,
                        "page": 3,
                        "original_page": 3
                    },
                    {
                        "left": 0.05310457516339869,
                        "top": 0.11607142857142858,
                        "width": 0.880718954248366,
                        "height": 0.2390873015873016,
                        "page": 3,
                        "original_page": 3
                    }
                ]
            },
            {
                "page_range": [
                    5,
                    5
                ],
                "bboxes": [
                    {
                        "left": 0.17320261437908496,
                        "top": 0.5441468253968254,
                        "width": 0.7189542483660131,
                        "height": 0.23015873015873015,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.7515846202576083,
                        "top": 0.5648819454132564,
                        "width": 0.2263098822699653,
                        "height": 0.13725365532769096,
                        "page": 5,
                        "original_page": 5
                    }
                ]
            }
        ]
    },
    "district": {
        "value": "Worli",
        "sources": [
            {
                "page_range": [
                    5,
                    5
                ],
                "bboxes": [
                    {
                        "left": 0.17320261437908496,
                        "top": 0.5441468253968254,
                        "width": 0.7189542483660131,
                        "height": 0.23015873015873015,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.7515846202576083,
                        "top": 0.5648819454132564,
                        "width": 0.2263098822699653,
                        "height": 0.13725365532769096,
                        "page": 5,
                        "original_page": 5
                    }
                ]
            }
        ]
    },
    "village": {
        "value": "Worli",
        "sources": [
            {
                "page_range": [
                    4,
                    5
                ],
                "bboxes": [
                    {
                        "left": 0.23788088131574245,
                        "top": 0.649208250499907,
                        "width": 0.2232623131446589,
                        "height": 0.107147216796875,
                        "page": 4,
                        "original_page": 4
                    },
                    {
                        "left": 0.7336601307189542,
                        "top": 0.7078373015873016,
                        "width": 0.03349673202614379,
                        "height": 0.01636904761904762,
                        "page": 4,
                        "original_page": 4
                    },
                    {
                        "left": 0.5130718954248366,
                        "top": 0.7351190476190477,
                        "width": 0.25735294117647056,
                        "height": 0.026785714285714284,
                        "page": 4,
                        "original_page": 4
                    },
                    {
                        "left": 0.5751633986928104,
                        "top": 0.7797619047619048,
                        "width": 0.1454248366013072,
                        "height": 0.015873015873015872,
                        "page": 4,
                        "original_page": 4
                    },
                    {
                        "left": 0.0016339869281045752,
                        "top": 0.008432539682539682,
                        "width": 0.005718954248366013,
                        "height": 0.005456349206349206,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.35784313725490197,
                        "top": 0.11607142857142858,
                        "width": 0.2540849673202614,
                        "height": 0.011408730158730158,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.16748366013071894,
                        "top": 0.12946428571428573,
                        "width": 0.6552287581699346,
                        "height": 0.04811507936507937,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.8219704222834967,
                        "top": 0.14273909916953434,
                        "width": 0.04332399056627859,
                        "height": 0.02183790055532304,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.8911672355302798,
                        "top": 0.14023450821165054,
                        "width": 0.04330484228196487,
                        "height": 0.01920952872624473,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.45016339869281047,
                        "top": 0.19642857142857142,
                        "width": 0.0718954248366013,
                        "height": 0.008432539682539682,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.16911764705882354,
                        "top": 0.22668650793650794,
                        "width": 0.6380718954248366,
                        "height": 0.09176587301587301,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.4722222222222222,
                        "top": 0.33978174603174605,
                        "width": 0.03431372549019608,
                        "height": 0.006944444444444444,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.1715686274509804,
                        "top": 0.3685515873015873,
                        "width": 0.6380718954248366,
                        "height": 0.09176587301587301,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.17320261437908496,
                        "top": 0.4791666666666667,
                        "width": 0.636437908496732,
                        "height": 0.04265873015873016,
                        "page": 5,
                        "original_page": 5
                    }
                ]
            },
            {
                "page_range": [
                    5,
                    5
                ],
                "bboxes": [
                    {
                        "left": 0.17320261437908496,
                        "top": 0.5441468253968254,
                        "width": 0.7189542483660131,
                        "height": 0.23015873015873015,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.7515846202576083,
                        "top": 0.5648819454132564,
                        "width": 0.2263098822699653,
                        "height": 0.13725365532769096,
                        "page": 5,
                        "original_page": 5
                    }
                ]
            },
            {
                "page_range": [
                    5,
                    6
                ],
                "bboxes": [
                    {
                        "left": 0.17647058823529413,
                        "top": 0.7966269841269841,
                        "width": 0.636437908496732,
                        "height": 0.02628968253968254,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.15020111183715024,
                        "top": 0.8445979527064732,
                        "width": 0.13207651275435303,
                        "height": 0.08065529475136408,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.38132748572654973,
                        "top": 0.828873043968564,
                        "width": 0.2876784630071104,
                        "height": 0.11042325458829365,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.7604768042470894,
                        "top": 0.8320226517934648,
                        "width": 0.16923722410513684,
                        "height": 0.06536332387772817,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.2107843137254902,
                        "top": 0.09970238095238096,
                        "width": 0.5923202614379085,
                        "height": 0.04017857142857143,
                        "page": 6,
                        "original_page": 6
                    }
                ]
            },
            {
                "page_range": [
                    33,
                    33
                ],
                "bboxes": [
                    {
                        "left": 0.3790849673202614,
                        "top": 0.1865079365079365,
                        "width": 0.28513071895424835,
                        "height": 0.026785714285714284,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17565359477124182,
                        "top": 0.23313492063492064,
                        "width": 0.6462418300653595,
                        "height": 0.18501984126984128,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.332918703166488,
                        "top": 0.3859390379890563,
                        "width": 0.33196448033152065,
                        "height": 0.14046284509083581,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.1781045751633987,
                        "top": 0.439484126984127,
                        "width": 0.6388888888888888,
                        "height": 0.00992063492063492,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17892156862745098,
                        "top": 0.4558531746031746,
                        "width": 0.6380718954248366,
                        "height": 0.00992063492063492,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17892156862745098,
                        "top": 0.47172619047619047,
                        "width": 0.10784313725490197,
                        "height": 0.010912698412698412,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.1781045751633987,
                        "top": 0.501984126984127,
                        "width": 0.6372549019607843,
                        "height": 0.026785714285714284,
                        "page": 33,
                        "original_page": 33
                    }
                ]
            }
        ]
    },
    "khewat": {
        "value": "Cadastral Survey No. 1618 of Lower Parel Division",
        "sources": [
            {
                "page_range": [
                    5,
                    5
                ],
                "bboxes": [
                    {
                        "left": 0.17320261437908496,
                        "top": 0.5441468253968254,
                        "width": 0.7189542483660131,
                        "height": 0.23015873015873015,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.7515846202576083,
                        "top": 0.5648819454132564,
                        "width": 0.2263098822699653,
                        "height": 0.13725365532769096,
                        "page": 5,
                        "original_page": 5
                    }
                ]
            }
        ]
    },
    "municipality": {
        "value": "Worli, Mumbai",
        "sources": [
            {
                "page_range": [
                    2,
                    2
                ],
                "bboxes": [
                    {
                        "left": 0.2908496732026144,
                        "top": 0.09176587301587301,
                        "width": 0.42892156862745096,
                        "height": 0.015873015873015872,
                        "page": 2,
                        "original_page": 2
                    },
                    {
                        "left": 0.06290849673202614,
                        "top": 0.1130952380952381,
                        "width": 0.8766339869281046,
                        "height": 0.26884920634920634,
                        "page": 2,
                        "original_page": 2
                    }
                ]
            },
            {
                "page_range": [
                    3,
                    3
                ],
                "bboxes": [
                    {
                        "left": 0.28022875816993464,
                        "top": 0.08928571428571429,
                        "width": 0.42892156862745096,
                        "height": 0.01984126984126984,
                        "page": 3,
                        "original_page": 3
                    },
                    {
                        "left": 0.05310457516339869,
                        "top": 0.11607142857142858,
                        "width": 0.880718954248366,
                        "height": 0.2390873015873016,
                        "page": 3,
                        "original_page": 3
                    }
                ]
            },
            {
                "page_range": [
                    5,
                    5
                ],
                "bboxes": [
                    {
                        "left": 0.17320261437908496,
                        "top": 0.5441468253968254,
                        "width": 0.7189542483660131,
                        "height": 0.23015873015873015,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.7515846202576083,
                        "top": 0.5648819454132564,
                        "width": 0.2263098822699653,
                        "height": 0.13725365532769096,
                        "page": 5,
                        "original_page": 5
                    }
                ]
            },
            {
                "page_range": [
                    5,
                    6
                ],
                "bboxes": [
                    {
                        "left": 0.17647058823529413,
                        "top": 0.7966269841269841,
                        "width": 0.636437908496732,
                        "height": 0.02628968253968254,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.15020111183715024,
                        "top": 0.8445979527064732,
                        "width": 0.13207651275435303,
                        "height": 0.08065529475136408,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.38132748572654973,
                        "top": 0.828873043968564,
                        "width": 0.2876784630071104,
                        "height": 0.11042325458829365,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.7604768042470894,
                        "top": 0.8320226517934648,
                        "width": 0.16923722410513684,
                        "height": 0.06536332387772817,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.2107843137254902,
                        "top": 0.09970238095238096,
                        "width": 0.5923202614379085,
                        "height": 0.04017857142857143,
                        "page": 6,
                        "original_page": 6
                    }
                ]
            },
            {
                "page_range": [
                    6,
                    6
                ],
                "bboxes": [
                    {
                        "left": 0.1650326797385621,
                        "top": 0.16170634920634921,
                        "width": 0.636437908496732,
                        "height": 0.2013888888888889,
                        "page": 6,
                        "original_page": 6
                    }
                ]
            },
            {
                "page_range": [
                    33,
                    33
                ],
                "bboxes": [
                    {
                        "left": 0.3790849673202614,
                        "top": 0.1865079365079365,
                        "width": 0.28513071895424835,
                        "height": 0.026785714285714284,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17565359477124182,
                        "top": 0.23313492063492064,
                        "width": 0.6462418300653595,
                        "height": 0.18501984126984128,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.332918703166488,
                        "top": 0.3859390379890563,
                        "width": 0.33196448033152065,
                        "height": 0.14046284509083581,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.1781045751633987,
                        "top": 0.439484126984127,
                        "width": 0.6388888888888888,
                        "height": 0.00992063492063492,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17892156862745098,
                        "top": 0.4558531746031746,
                        "width": 0.6380718954248366,
                        "height": 0.00992063492063492,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17892156862745098,
                        "top": 0.47172619047619047,
                        "width": 0.10784313725490197,
                        "height": 0.010912698412698412,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.1781045751633987,
                        "top": 0.501984126984127,
                        "width": 0.6372549019607843,
                        "height": 0.026785714285714284,
                        "page": 33,
                        "original_page": 33
                    }
                ]
            },
            {
                "page_range": [
                    35,
                    37
                ],
                "bboxes": [
                    {
                        "left": 0.38235294117647056,
                        "top": 0.07688492063492064,
                        "width": 0.23366013071895425,
                        "height": 0.024305555555555556,
                        "page": 35,
                        "original_page": 35
                    },
                    {
                        "left": 0.20745915680928947,
                        "top": 0.1802066924080016,
                        "width": 0.5844675076553245,
                        "height": 0.3813768114362444,
                        "page": 35,
                        "original_page": 35
                    },
                    {
                        "left": 0.20506535947712418,
                        "top": 0.5625,
                        "width": 0.1830065359477124,
                        "height": 0.004464285714285714,
                        "page": 35,
                        "original_page": 35
                    },
                    {
                        "left": 0.2034313725490196,
                        "top": 0.5749007936507936,
                        "width": 0.056372549019607844,
                        "height": 0.00496031746031746,
                        "page": 35,
                        "original_page": 35
                    },
                    {
                        "left": 0.2034313725490196,
                        "top": 0.5818452380952381,
                        "width": 0.38235294117647056,
                        "height": 0.010416666666666666,
                        "page": 35,
                        "original_page": 35
                    },
                    {
                        "left": 0.3668876847410514,
                        "top": 0.5975355118040054,
                        "width": 0.22792334961735344,
                        "height": 0.13573570856972347,
                        "page": 35,
                        "original_page": 35
                    },
                    {
                        "left": 0.6219095965616064,
                        "top": 0.5953616793193515,
                        "width": 0.29161006952422897,
                        "height": 0.11660197424510169,
                        "page": 35,
                        "original_page": 35
                    },
                    {
                        "left": 0.18520811492321537,
                        "top": 0.7342657664465526,
                        "width": 0.1318614186804279,
                        "height": 0.08205758957635789,
                        "page": 35,
                        "original_page": 35
                    },
                    {
                        "left": 0.5949306612700419,
                        "top": 0.7284373328799293,
                        "width": 0.10721239077499489,
                        "height": 0.06572360084170387,
                        "page": 35,
                        "original_page": 35
                    },
                    {
                        "left": 0.4869281045751634,
                        "top": 0.8784722222222222,
                        "width": 0.015522875816993464,
                        "height": 0.005952380952380952,
                        "page": 35,
                        "original_page": 35
                    },
                    {
                        "left": 0.3553921568627451,
                        "top": 0.10317460317460317,
                        "width": 0.22712418300653595,
                        "height": 0.024305555555555556,
                        "page": 36,
                        "original_page": 36
                    },
                    {
                        "left": 0.19999893038880592,
                        "top": 0.22436040545266772,
                        "width": 0.49713096119999106,
                        "height": 0.35415611569843597,
                        "page": 36,
                        "original_page": 36
                    },
                    {
                        "left": 0.11332529354718776,
                        "top": 0.5889329456147694,
                        "width": 0.226616042891359,
                        "height": 0.13722677079458084,
                        "page": 36,
                        "original_page": 36
                    },
                    {
                        "left": 0.3235294117647059,
                        "top": 0.6125992063492064,
                        "width": 0.08006535947712418,
                        "height": 0.005952380952380952,
                        "page": 36,
                        "original_page": 36
                    },
                    {
                        "left": 0.6094771241830066,
                        "top": 0.6016865079365079,
                        "width": 0.004901960784313725,
                        "height": 0.00248015873015873,
                        "page": 36,
                        "original_page": 36
                    },
                    {
                        "left": 0.7249686047921773,
                        "top": 0.578788091265966,
                        "width": 0.03988213632621017,
                        "height": 0.03690392630440848,
                        "page": 36,
                        "original_page": 36
                    },
                    {
                        "left": 0.31699346405228757,
                        "top": 0.6264880952380952,
                        "width": 0.24591503267973855,
                        "height": 0.010912698412698412,
                        "page": 36,
                        "original_page": 36
                    },
                    {
                        "left": 0.11138502133438011,
                        "top": 0.7484103006029886,
                        "width": 0.30188175276214,
                        "height": 0.2101566375248016,
                        "page": 36,
                        "original_page": 36
                    },
                    {
                        "left": 0.4982251435323478,
                        "top": 0.7519014752100385,
                        "width": 0.18543587478936888,
                        "height": 0.06667140173533606,
                        "page": 36,
                        "original_page": 36
                    },
                    {
                        "left": 0.46405228758169936,
                        "top": 0.9032738095238095,
                        "width": 0.016339869281045753,
                        "height": 0.005952380952380952,
                        "page": 36,
                        "original_page": 36
                    },
                    {
                        "left": 0.4452614379084967,
                        "top": 0.07589285714285714,
                        "width": 0.08823529411764706,
                        "height": 0.008432539682539682,
                        "page": 37,
                        "original_page": 37
                    },
                    {
                        "left": 0.190359477124183,
                        "top": 0.10813492063492064,
                        "width": 0.5996732026143791,
                        "height": 0.03968253968253968,
                        "page": 37,
                        "original_page": 37
                    },
                    {
                        "left": 0.17401960784313725,
                        "top": 0.15376984126984128,
                        "width": 0.3741830065359477,
                        "height": 0.013392857142857142,
                        "page": 37,
                        "original_page": 37
                    }
                ]
            }
        ]
    },
    "registrarOffice": {
        "value": "IGR183-BOM2 JT SUB REGISTRA MUMBAI CITY 2",
        "sources": [
            {
                "page_range": [
                    2,
                    2
                ],
                "bboxes": [
                    {
                        "left": 0.2908496732026144,
                        "top": 0.09176587301587301,
                        "width": 0.42892156862745096,
                        "height": 0.015873015873015872,
                        "page": 2,
                        "original_page": 2
                    },
                    {
                        "left": 0.06290849673202614,
                        "top": 0.1130952380952381,
                        "width": 0.8766339869281046,
                        "height": 0.26884920634920634,
                        "page": 2,
                        "original_page": 2
                    }
                ]
            },
            {
                "page_range": [
                    3,
                    3
                ],
                "bboxes": [
                    {
                        "left": 0.28022875816993464,
                        "top": 0.08928571428571429,
                        "width": 0.42892156862745096,
                        "height": 0.01984126984126984,
                        "page": 3,
                        "original_page": 3
                    },
                    {
                        "left": 0.05310457516339869,
                        "top": 0.11607142857142858,
                        "width": 0.880718954248366,
                        "height": 0.2390873015873016,
                        "page": 3,
                        "original_page": 3
                    }
                ]
            },
            {
                "page_range": [
                    64,
                    67
                ],
                "bboxes": [
                    {
                        "left": 0.4575963238485498,
                        "top": 0.7936187017531622,
                        "width": 0.29566028071384803,
                        "height": 0.11898543342711433,
                        "page": 64,
                        "original_page": 64
                    },
                    {
                        "left": 0.4277791041953891,
                        "top": 0.24037623027014354,
                        "width": 0.4086346595115911,
                        "height": 0.20766150762164404,
                        "page": 65,
                        "original_page": 65
                    },
                    {
                        "left": 0.3300653594771242,
                        "top": 0.48561507936507936,
                        "width": 0.5702614379084967,
                        "height": 0.12549603174603174,
                        "page": 65,
                        "original_page": 65
                    },
                    {
                        "left": 0.30082545560948987,
                        "top": 0.613699474031963,
                        "width": 0.61509881611743,
                        "height": 0.18479023282490079,
                        "page": 65,
                        "original_page": 65
                    },
                    {
                        "left": 0.138875200857524,
                        "top": 0.5182140895298549,
                        "width": 0.22832486520405687,
                        "height": 0.13674212258959573,
                        "page": 66,
                        "original_page": 66
                    },
                    {
                        "left": 0.04426724926318998,
                        "top": 0.6673591855972533,
                        "width": 0.2926222046995475,
                        "height": 0.1184554932609437,
                        "page": 66,
                        "original_page": 66
                    },
                    {
                        "left": 0.14869281045751634,
                        "top": 0.031746031746031744,
                        "width": 0.6143790849673203,
                        "height": 0.046130952380952384,
                        "page": 67,
                        "original_page": 67
                    },
                    {
                        "left": 0.05065359477124183,
                        "top": 0.10416666666666667,
                        "width": 0.7181372549019608,
                        "height": 0.07738095238095238,
                        "page": 67,
                        "original_page": 67
                    },
                    {
                        "left": 0.05473856209150327,
                        "top": 0.19593253968253968,
                        "width": 0.7116013071895425,
                        "height": 0.029265873015873016,
                        "page": 67,
                        "original_page": 67
                    }
                ]
            },
            {
                "page_range": [
                    69,
                    69
                ],
                "bboxes": [
                    {
                        "left": 0.1503267973856209,
                        "top": 0.03125,
                        "width": 0.6151960784313726,
                        "height": 0.046130952380952384,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.05065359477124183,
                        "top": 0.10317460317460317,
                        "width": 0.5024509803921569,
                        "height": 0.07787698412698413,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.46405228758169936,
                        "top": 0.10863095238095238,
                        "width": 0.30637254901960786,
                        "height": 0.051587301587301584,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.05555555555555555,
                        "top": 0.19593253968253968,
                        "width": 0.5964052287581699,
                        "height": 0.029265873015873016,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.05800653594771242,
                        "top": 0.24206349206349206,
                        "width": 0.09640522875816994,
                        "height": 0.0248015873015873,
                        "page": 69,
                        "original_page": 69
                    }
                ]
            },
            {
                "page_range": [
                    73,
                    73
                ],
                "bboxes": [
                    {
                        "left": 0.3684640522875817,
                        "top": 0.5555555555555556,
                        "width": 0.05228758169934641,
                        "height": 0.023809523809523808,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.05473856209150327,
                        "top": 0.5746031746031746,
                        "width": 0.9158496732026143,
                        "height": 0.2165079365079365,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.06699346405228758,
                        "top": 0.7923809523809524,
                        "width": 0.4673202614379085,
                        "height": 0.009206349206349206,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.06944444444444445,
                        "top": 0.8247619047619048,
                        "width": 0.5196078431372549,
                        "height": 0.028888888888888888,
                        "page": 73,
                        "original_page": 73
                    }
                ]
            }
        ]
    },
    "agreementType": {
        "value": LeaseType.LEAVE_AND_LICENSE,
        "sources": [
            {
                "page_range": [
                    2,
                    2
                ],
                "bboxes": [
                    {
                        "left": 0.2908496732026144,
                        "top": 0.09176587301587301,
                        "width": 0.42892156862745096,
                        "height": 0.015873015873015872,
                        "page": 2,
                        "original_page": 2
                    },
                    {
                        "left": 0.06290849673202614,
                        "top": 0.1130952380952381,
                        "width": 0.8766339869281046,
                        "height": 0.26884920634920634,
                        "page": 2,
                        "original_page": 2
                    }
                ]
            },
            {
                "page_range": [
                    3,
                    3
                ],
                "bboxes": [
                    {
                        "left": 0.28022875816993464,
                        "top": 0.08928571428571429,
                        "width": 0.42892156862745096,
                        "height": 0.01984126984126984,
                        "page": 3,
                        "original_page": 3
                    },
                    {
                        "left": 0.05310457516339869,
                        "top": 0.11607142857142858,
                        "width": 0.880718954248366,
                        "height": 0.2390873015873016,
                        "page": 3,
                        "original_page": 3
                    }
                ]
            },
            {
                "page_range": [
                    4,
                    5
                ],
                "bboxes": [
                    {
                        "left": 0.23788088131574245,
                        "top": 0.649208250499907,
                        "width": 0.2232623131446589,
                        "height": 0.107147216796875,
                        "page": 4,
                        "original_page": 4
                    },
                    {
                        "left": 0.7336601307189542,
                        "top": 0.7078373015873016,
                        "width": 0.03349673202614379,
                        "height": 0.01636904761904762,
                        "page": 4,
                        "original_page": 4
                    },
                    {
                        "left": 0.5130718954248366,
                        "top": 0.7351190476190477,
                        "width": 0.25735294117647056,
                        "height": 0.026785714285714284,
                        "page": 4,
                        "original_page": 4
                    },
                    {
                        "left": 0.5751633986928104,
                        "top": 0.7797619047619048,
                        "width": 0.1454248366013072,
                        "height": 0.015873015873015872,
                        "page": 4,
                        "original_page": 4
                    },
                    {
                        "left": 0.0016339869281045752,
                        "top": 0.008432539682539682,
                        "width": 0.005718954248366013,
                        "height": 0.005456349206349206,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.35784313725490197,
                        "top": 0.11607142857142858,
                        "width": 0.2540849673202614,
                        "height": 0.011408730158730158,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.16748366013071894,
                        "top": 0.12946428571428573,
                        "width": 0.6552287581699346,
                        "height": 0.04811507936507937,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.8219704222834967,
                        "top": 0.14273909916953434,
                        "width": 0.04332399056627859,
                        "height": 0.02183790055532304,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.8911672355302798,
                        "top": 0.14023450821165054,
                        "width": 0.04330484228196487,
                        "height": 0.01920952872624473,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.45016339869281047,
                        "top": 0.19642857142857142,
                        "width": 0.0718954248366013,
                        "height": 0.008432539682539682,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.16911764705882354,
                        "top": 0.22668650793650794,
                        "width": 0.6380718954248366,
                        "height": 0.09176587301587301,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.4722222222222222,
                        "top": 0.33978174603174605,
                        "width": 0.03431372549019608,
                        "height": 0.006944444444444444,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.1715686274509804,
                        "top": 0.3685515873015873,
                        "width": 0.6380718954248366,
                        "height": 0.09176587301587301,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.17320261437908496,
                        "top": 0.4791666666666667,
                        "width": 0.636437908496732,
                        "height": 0.04265873015873016,
                        "page": 5,
                        "original_page": 5
                    }
                ]
            }
        ]
    },
    "registrationDate": {
        "value": "2024-10-25",
        "sources": [
            {
                "page_range": [
                    72,
                    73
                ],
                "bboxes": [
                    {
                        "left": 0.923202614379085,
                        "top": 0.8015873015873016,
                        "width": 0.07352941176470588,
                        "height": 0.005456349206349206,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.4673202614379085,
                        "top": 0.8159722222222222,
                        "width": 0.14787581699346405,
                        "height": 0.005952380952380952,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.09722222222222222,
                        "top": 0.8268849206349206,
                        "width": 0.47630718954248363,
                        "height": 0.010416666666666666,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.09640522875816994,
                        "top": 0.8407738095238095,
                        "width": 0.1854575163398693,
                        "height": 0.00744047619047619,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.39950980392156865,
                        "top": 0.847718253968254,
                        "width": 0.2826797385620915,
                        "height": 0.008432539682539682,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.06345455942590253,
                        "top": 0.035385972280350945,
                        "width": 0.24202333088793787,
                        "height": 0.020585392543247767,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.06209150326797386,
                        "top": 0.057777777777777775,
                        "width": 0.14624183006535948,
                        "height": 0.007301587301587302,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.4526143790849673,
                        "top": 0.03841269841269841,
                        "width": 0.10702614379084967,
                        "height": 0.007301587301587302,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.6478758169934641,
                        "top": 0.03365079365079365,
                        "width": 0.3006535947712418,
                        "height": 0.026984126984126985,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.06454248366013073,
                        "top": 0.07777777777777778,
                        "width": 0.16258169934640523,
                        "height": 0.007936507936507936,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.06290849673202614,
                        "top": 0.09047619047619047,
                        "width": 0.12091503267973856,
                        "height": 0.009206349206349206,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.06862745098039216,
                        "top": 0.11777777777777777,
                        "width": 0.1854575163398693,
                        "height": 0.009523809523809525,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.5106209150326797,
                        "top": 0.11873015873015873,
                        "width": 0.08333333333333333,
                        "height": 0.005079365079365079,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.10130718954248366,
                        "top": 0.13174603174603175,
                        "width": 0.4542483660130719,
                        "height": 0.05238095238095238,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.5294117647058824,
                        "top": 0.16095238095238096,
                        "width": 0.0784313725490196,
                        "height": 0.023174603174603174,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.7034313725490197,
                        "top": 0.11555555555555555,
                        "width": 0.05310457516339869,
                        "height": 0.008253968253968255,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.8513071895424836,
                        "top": 0.11460317460317461,
                        "width": 0.06781045751633986,
                        "height": 0.009523809523809525,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.6387384452071845,
                        "top": 0.12103157164558531,
                        "width": 0.13302916483162275,
                        "height": 0.07191962348090278,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.8147793938131893,
                        "top": 0.12790081690228175,
                        "width": 0.09866218317567912,
                        "height": 0.0647418697296627,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.06209150326797386,
                        "top": 0.20666666666666667,
                        "width": 0.4534313725490196,
                        "height": 0.020317460317460317,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.061274509803921566,
                        "top": 0.24095238095238095,
                        "width": 0.5522875816993464,
                        "height": 0.019365079365079366,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.07026143790849673,
                        "top": 0.26571428571428574,
                        "width": 0.1568627450980392,
                        "height": 0.010158730158730159,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.08905228758169935,
                        "top": 0.28095238095238095,
                        "width": 0.15931372549019607,
                        "height": 0.040317460317460314,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.08986928104575163,
                        "top": 0.34793650793650793,
                        "width": 0.17565359477124182,
                        "height": 0.04,
                        "page": 73,
                        "original_page": 73
                    }
                ]
            }
        ]
    },
    "unit": {
        "value": "4101",
        "sources": [
            {
                "page_range": [
                    2,
                    2
                ],
                "bboxes": [
                    {
                        "left": 0.2908496732026144,
                        "top": 0.09176587301587301,
                        "width": 0.42892156862745096,
                        "height": 0.015873015873015872,
                        "page": 2,
                        "original_page": 2
                    },
                    {
                        "left": 0.06290849673202614,
                        "top": 0.1130952380952381,
                        "width": 0.8766339869281046,
                        "height": 0.26884920634920634,
                        "page": 2,
                        "original_page": 2
                    }
                ]
            },
            {
                "page_range": [
                    3,
                    3
                ],
                "bboxes": [
                    {
                        "left": 0.28022875816993464,
                        "top": 0.08928571428571429,
                        "width": 0.42892156862745096,
                        "height": 0.01984126984126984,
                        "page": 3,
                        "original_page": 3
                    },
                    {
                        "left": 0.05310457516339869,
                        "top": 0.11607142857142858,
                        "width": 0.880718954248366,
                        "height": 0.2390873015873016,
                        "page": 3,
                        "original_page": 3
                    }
                ]
            },
            {
                "page_range": [
                    6,
                    6
                ],
                "bboxes": [
                    {
                        "left": 0.1650326797385621,
                        "top": 0.16170634920634921,
                        "width": 0.636437908496732,
                        "height": 0.2013888888888889,
                        "page": 6,
                        "original_page": 6
                    }
                ]
            },
            {
                "page_range": [
                    33,
                    33
                ],
                "bboxes": [
                    {
                        "left": 0.3790849673202614,
                        "top": 0.1865079365079365,
                        "width": 0.28513071895424835,
                        "height": 0.026785714285714284,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17565359477124182,
                        "top": 0.23313492063492064,
                        "width": 0.6462418300653595,
                        "height": 0.18501984126984128,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.332918703166488,
                        "top": 0.3859390379890563,
                        "width": 0.33196448033152065,
                        "height": 0.14046284509083581,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.1781045751633987,
                        "top": 0.439484126984127,
                        "width": 0.6388888888888888,
                        "height": 0.00992063492063492,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17892156862745098,
                        "top": 0.4558531746031746,
                        "width": 0.6380718954248366,
                        "height": 0.00992063492063492,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17892156862745098,
                        "top": 0.47172619047619047,
                        "width": 0.10784313725490197,
                        "height": 0.010912698412698412,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.1781045751633987,
                        "top": 0.501984126984127,
                        "width": 0.6372549019607843,
                        "height": 0.026785714285714284,
                        "page": 33,
                        "original_page": 33
                    }
                ]
            },
            {
                "page_range": [
                    67,
                    68
                ],
                "bboxes": [
                    {
                        "left": 0.05718954248366013,
                        "top": 0.21478174603174602,
                        "width": 0.7165032679738562,
                        "height": 0.08035714285714286,
                        "page": 67,
                        "original_page": 67
                    },
                    {
                        "left": 0.059640522875817,
                        "top": 0.2986111111111111,
                        "width": 0.6854575163398693,
                        "height": 0.09424603174603174,
                        "page": 67,
                        "original_page": 67
                    },
                    {
                        "left": 0.03464924432093801,
                        "top": 0.4076282259017702,
                        "width": 0.255486606772429,
                        "height": 0.11350162445552765,
                        "page": 67,
                        "original_page": 67
                    },
                    {
                        "left": 0.05392156862745098,
                        "top": 0.5287698412698413,
                        "width": 0.8978758169934641,
                        "height": 0.06597222222222222,
                        "page": 67,
                        "original_page": 67
                    },
                    {
                        "left": 0.33560415031084045,
                        "top": 0.625603267124721,
                        "width": 0.22573563320184845,
                        "height": 0.13520050048828125,
                        "page": 67,
                        "original_page": 67
                    },
                    {
                        "left": 0.5768233155892566,
                        "top": 0.6532543727329799,
                        "width": 0.2836902094822304,
                        "height": 0.10862725878518725,
                        "page": 67,
                        "original_page": 67
                    },
                    {
                        "left": 0.15507289163427415,
                        "top": 0.48601226201133124,
                        "width": 0.22873175377939262,
                        "height": 0.13793603200761098,
                        "page": 68,
                        "original_page": 68
                    },
                    {
                        "left": 0.056899320066364764,
                        "top": 0.6512043059818329,
                        "width": 0.29056170719121793,
                        "height": 0.11484848506866939,
                        "page": 68,
                        "original_page": 68
                    }
                ]
            },
            {
                "page_range": [
                    69,
                    70
                ],
                "bboxes": [
                    {
                        "left": 0.1919934640522876,
                        "top": 0.21626984126984128,
                        "width": 0.5808823529411765,
                        "height": 0.07886904761904762,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.059640522875817,
                        "top": 0.2986111111111111,
                        "width": 0.6862745098039216,
                        "height": 0.09375,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.036518368066525926,
                        "top": 0.40847911531963044,
                        "width": 0.2542090104296317,
                        "height": 0.11437564425998265,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.05310457516339869,
                        "top": 0.5287698412698413,
                        "width": 0.8978758169934641,
                        "height": 0.052083333333333336,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.3214109333512051,
                        "top": 0.6635828775072855,
                        "width": 0.2280039569131689,
                        "height": 0.13616016932896205,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.582526462529999,
                        "top": 0.6644446357848153,
                        "width": 0.28015141705282376,
                        "height": 0.10952661907862103,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.31731923421223956,
                        "top": 0.19365333375476657,
                        "width": 0.06492467643388736,
                        "height": 0.03634834289550781,
                        "page": 70,
                        "original_page": 70
                    },
                    {
                        "left": 0.3872549019607843,
                        "top": 0.19345238095238096,
                        "width": 0.31290849673202614,
                        "height": 0.03720238095238095,
                        "page": 70,
                        "original_page": 70
                    }
                ]
            }
        ]
    },
    "floor": {
        "value": "41",
        "sources": [
            {
                "page_range": [
                    3,
                    3
                ],
                "bboxes": [
                    {
                        "left": 0.28022875816993464,
                        "top": 0.08928571428571429,
                        "width": 0.42892156862745096,
                        "height": 0.01984126984126984,
                        "page": 3,
                        "original_page": 3
                    },
                    {
                        "left": 0.05310457516339869,
                        "top": 0.11607142857142858,
                        "width": 0.880718954248366,
                        "height": 0.2390873015873016,
                        "page": 3,
                        "original_page": 3
                    }
                ]
            },
            {
                "page_range": [
                    6,
                    6
                ],
                "bboxes": [
                    {
                        "left": 0.1650326797385621,
                        "top": 0.16170634920634921,
                        "width": 0.636437908496732,
                        "height": 0.2013888888888889,
                        "page": 6,
                        "original_page": 6
                    }
                ]
            },
            {
                "page_range": [
                    33,
                    33
                ],
                "bboxes": [
                    {
                        "left": 0.3790849673202614,
                        "top": 0.1865079365079365,
                        "width": 0.28513071895424835,
                        "height": 0.026785714285714284,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17565359477124182,
                        "top": 0.23313492063492064,
                        "width": 0.6462418300653595,
                        "height": 0.18501984126984128,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.332918703166488,
                        "top": 0.3859390379890563,
                        "width": 0.33196448033152065,
                        "height": 0.14046284509083581,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.1781045751633987,
                        "top": 0.439484126984127,
                        "width": 0.6388888888888888,
                        "height": 0.00992063492063492,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17892156862745098,
                        "top": 0.4558531746031746,
                        "width": 0.6380718954248366,
                        "height": 0.00992063492063492,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17892156862745098,
                        "top": 0.47172619047619047,
                        "width": 0.10784313725490197,
                        "height": 0.010912698412698412,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.1781045751633987,
                        "top": 0.501984126984127,
                        "width": 0.6372549019607843,
                        "height": 0.026785714285714284,
                        "page": 33,
                        "original_page": 33
                    }
                ]
            },
            {
                "page_range": [
                    69,
                    70
                ],
                "bboxes": [
                    {
                        "left": 0.1919934640522876,
                        "top": 0.21626984126984128,
                        "width": 0.5808823529411765,
                        "height": 0.07886904761904762,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.059640522875817,
                        "top": 0.2986111111111111,
                        "width": 0.6862745098039216,
                        "height": 0.09375,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.036518368066525926,
                        "top": 0.40847911531963044,
                        "width": 0.2542090104296317,
                        "height": 0.11437564425998265,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.05310457516339869,
                        "top": 0.5287698412698413,
                        "width": 0.8978758169934641,
                        "height": 0.052083333333333336,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.3214109333512051,
                        "top": 0.6635828775072855,
                        "width": 0.2280039569131689,
                        "height": 0.13616016932896205,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.582526462529999,
                        "top": 0.6644446357848153,
                        "width": 0.28015141705282376,
                        "height": 0.10952661907862103,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.31731923421223956,
                        "top": 0.19365333375476657,
                        "width": 0.06492467643388736,
                        "height": 0.03634834289550781,
                        "page": 70,
                        "original_page": 70
                    },
                    {
                        "left": 0.3872549019607843,
                        "top": 0.19345238095238096,
                        "width": 0.31290849673202614,
                        "height": 0.03720238095238095,
                        "page": 70,
                        "original_page": 70
                    }
                ]
            }
        ]
    },
    "wing": {
        "value": "41st floor",
        "sources": [
            {
                "page_range": [
                    6,
                    6
                ],
                "bboxes": [
                    {
                        "left": 0.1650326797385621,
                        "top": 0.16170634920634921,
                        "width": 0.636437908496732,
                        "height": 0.2013888888888889,
                        "page": 6,
                        "original_page": 6
                    }
                ]
            },
            {
                "page_range": [
                    33,
                    33
                ],
                "bboxes": [
                    {
                        "left": 0.3790849673202614,
                        "top": 0.1865079365079365,
                        "width": 0.28513071895424835,
                        "height": 0.026785714285714284,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17565359477124182,
                        "top": 0.23313492063492064,
                        "width": 0.6462418300653595,
                        "height": 0.18501984126984128,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.332918703166488,
                        "top": 0.3859390379890563,
                        "width": 0.33196448033152065,
                        "height": 0.14046284509083581,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.1781045751633987,
                        "top": 0.439484126984127,
                        "width": 0.6388888888888888,
                        "height": 0.00992063492063492,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17892156862745098,
                        "top": 0.4558531746031746,
                        "width": 0.6380718954248366,
                        "height": 0.00992063492063492,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17892156862745098,
                        "top": 0.47172619047619047,
                        "width": 0.10784313725490197,
                        "height": 0.010912698412698412,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.1781045751633987,
                        "top": 0.501984126984127,
                        "width": 0.6372549019607843,
                        "height": 0.026785714285714284,
                        "page": 33,
                        "original_page": 33
                    }
                ]
            }
        ]
    },
    "addressExtraDiscriminator": {
        "value": null,
        "sources": [
            {
                "page_range": [
                    6,
                    6
                ],
                "bboxes": [
                    {
                        "left": 0.1650326797385621,
                        "top": 0.16170634920634921,
                        "width": 0.636437908496732,
                        "height": 0.2013888888888889,
                        "page": 6,
                        "original_page": 6
                    }
                ]
            },
            {
                "page_range": [
                    33,
                    33
                ],
                "bboxes": [
                    {
                        "left": 0.3790849673202614,
                        "top": 0.1865079365079365,
                        "width": 0.28513071895424835,
                        "height": 0.026785714285714284,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17565359477124182,
                        "top": 0.23313492063492064,
                        "width": 0.6462418300653595,
                        "height": 0.18501984126984128,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.332918703166488,
                        "top": 0.3859390379890563,
                        "width": 0.33196448033152065,
                        "height": 0.14046284509083581,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.1781045751633987,
                        "top": 0.439484126984127,
                        "width": 0.6388888888888888,
                        "height": 0.00992063492063492,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17892156862745098,
                        "top": 0.4558531746031746,
                        "width": 0.6380718954248366,
                        "height": 0.00992063492063492,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17892156862745098,
                        "top": 0.47172619047619047,
                        "width": 0.10784313725490197,
                        "height": 0.010912698412698412,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.1781045751633987,
                        "top": 0.501984126984127,
                        "width": 0.6372549019607843,
                        "height": 0.026785714285714284,
                        "page": 33,
                        "original_page": 33
                    }
                ]
            },
            {
                "page_range": [
                    57,
                    57
                ],
                "bboxes": [
                    {
                        "left": 0.13970588235294118,
                        "top": 0.1984126984126984,
                        "width": 0.7467320261437909,
                        "height": 0.09722222222222222,
                        "page": 57,
                        "original_page": 57
                    },
                    {
                        "left": 0.14133986928104575,
                        "top": 0.31200396825396826,
                        "width": 0.7467320261437909,
                        "height": 0.06001984126984127,
                        "page": 57,
                        "original_page": 57
                    }
                ]
            }
        ]
    },
    "chargeableAreaType": {
        "value": AreaType.BUILTUP,
        "sources": [
            {
                "page_range": [

                    6,
                    6
                ],
                "bboxes": [
                    {
                        "left": 0.1650326797385621,
                        "top": 0.16170634920634921,
                        "width": 0.636437908496732,
                        "height": 0.2013888888888889,
                        "page": 6,
                        "original_page": 6
                    }
                ]
            },
            {
                "page_range": [
                    11,
                    11
                ],
                "bboxes": [
                    {
                        "left": 0.1781045751633987,
                        "top": 0.6016865079365079,
                        "width": 0.7565359477124183,
                        "height": 0.21378968253968253,
                        "page": 11,
                        "original_page": 11
                    },
                    {
                        "left": 0.8839869281045751,
                        "top": 0.6875,
                        "width": 0.03839869281045752,
                        "height": 0.018353174603174604,
                        "page": 11,
                        "original_page": 11
                    },
                    {
                        "left": 0.13020049201117623,
                        "top": 0.8457617381262401,
                        "width": 0.13362970539167815,
                        "height": 0.08008938743954613,
                        "page": 11,
                        "original_page": 11
                    },
                    {
                        "left": 0.4918300653594771,
                        "top": 0.8754960317460317,
                        "width": 0.00980392156862745,
                        "height": 0.006448412698412698,
                        "page": 11,
                        "original_page": 11
                    },
                    {
                        "left": 0.6676946901807598,
                        "top": 0.8746482606918092,
                        "width": 0.04530314526526757,
                        "height": 0.02327782767159598,
                        "page": 11,
                        "original_page": 11
                    },
                    {
                        "left": 0.7419550777260774,
                        "top": 0.8437042236328125,
                        "width": 0.10515434913385928,
                        "height": 0.06311719379727802,
                        "page": 11,
                        "original_page": 11
                    }
                ]
            },
            {
                "page_range": [
                    33,
                    33
                ],
                "bboxes": [
                    {
                        "left": 0.3790849673202614,
                        "top": 0.1865079365079365,
                        "width": 0.28513071895424835,
                        "height": 0.026785714285714284,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17565359477124182,
                        "top": 0.23313492063492064,
                        "width": 0.6462418300653595,
                        "height": 0.18501984126984128,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.332918703166488,
                        "top": 0.3859390379890563,
                        "width": 0.33196448033152065,
                        "height": 0.14046284509083581,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.1781045751633987,
                        "top": 0.439484126984127,
                        "width": 0.6388888888888888,
                        "height": 0.00992063492063492,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17892156862745098,
                        "top": 0.4558531746031746,
                        "width": 0.6380718954248366,
                        "height": 0.00992063492063492,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17892156862745098,
                        "top": 0.47172619047619047,
                        "width": 0.10784313725490197,
                        "height": 0.010912698412698412,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.1781045751633987,
                        "top": 0.501984126984127,
                        "width": 0.6372549019607843,
                        "height": 0.026785714285714284,
                        "page": 33,
                        "original_page": 33
                    }
                ]
            }
        ]
    },
    "agreementStartDate": {
        "value": "2024-10-01",
        "sources": [
            {
                "page_range": [
                    8,
                    8
                ],
                "bboxes": [
                    {
                        "left": 0.21650326797385622,
                        "top": 0.09970238095238096,
                        "width": 0.5866013071895425,
                        "height": 0.24950396825396826,
                        "page": 8,
                        "original_page": 8
                    }
                ]
            }
        ]
    },
    "agreementExpirationDate": {
        "value": "2034-10-01",
        "sources": []
    },
    "agreementDuration": {
        "value": 120,
        "sources": [
            {
                "page_range": [
                    8,
                    8
                ],
                "bboxes": [
                    {
                        "left": 0.21650326797385622,
                        "top": 0.09970238095238096,
                        "width": 0.5866013071895425,
                        "height": 0.24950396825396826,
                        "page": 8,
                        "original_page": 8
                    }
                ]
            }
        ]
    },
    "rentMonthly": {
        "value": 14945000,
        "sources": [
            {
                "page_range": [
                    12,
                    12
                ],
                "bboxes": [
                    {
                        "left": 0.20751633986928106,
                        "top": 0.5213293650793651,
                        "width": 0.0923202614379085,
                        "height": 0.008432539682539682,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.0941723405925277,
                        "top": 0.542098151312934,
                        "width": 0.2191973355860492,
                        "height": 0.13623004489474827,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.21405228758169934,
                        "top": 0.5530753968253969,
                        "width": 0.576797385620915,
                        "height": 0.10466269841269842,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.15359477124183007,
                        "top": 0.6780753968253969,
                        "width": 0.6356209150326797,
                        "height": 0.05853174603174603,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.06372549019607843,
                        "top": 0.7192460317460317,
                        "width": 0.3374183006535948,
                        "height": 0.09027777777777778,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.20261437908496732,
                        "top": 0.7872023809523809,
                        "width": 0.5866013071895425,
                        "height": 0.07341269841269842,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.08279160892262179,
                        "top": 0.8650077335418217,
                        "width": 0.13097883984933492,
                        "height": 0.07852306063213045,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.46568627450980393,
                        "top": 0.9037698412698413,
                        "width": 0.008986928104575163,
                        "height": 0.005456349206349206,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.6289296368368311,
                        "top": 0.889411865718781,
                        "width": 0.05064182655484069,
                        "height": 0.02953150915721106,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.7159576416015625,
                        "top": 0.8764419555664062,
                        "width": 0.10577198103362438,
                        "height": 0.0641952393546937,
                        "page": 12,
                        "original_page": 12
                    }
                ]
            }
        ]
    },
    "escalationTerms": {
        "value": "The license fee will escalate at a rate of 4.75% every 12 months, starting from the License Fee Commencement Date. Additionally, increases in municipal/property taxes beyond 4.75% per annum, starting from the 61st month of the License Fee Commencement Date, will be reimbursed to the Licensor by the Licensee.",
        "sources": [
            {
                "page_range": [
                    12,
                    12
                ],
                "bboxes": [
                    {
                        "left": 0.20751633986928106,
                        "top": 0.5213293650793651,
                        "width": 0.0923202614379085,
                        "height": 0.008432539682539682,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.0941723405925277,
                        "top": 0.542098151312934,
                        "width": 0.2191973355860492,
                        "height": 0.13623004489474827,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.21405228758169934,
                        "top": 0.5530753968253969,
                        "width": 0.576797385620915,
                        "height": 0.10466269841269842,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.15359477124183007,
                        "top": 0.6780753968253969,
                        "width": 0.6356209150326797,
                        "height": 0.05853174603174603,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.06372549019607843,
                        "top": 0.7192460317460317,
                        "width": 0.3374183006535948,
                        "height": 0.09027777777777778,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.20261437908496732,
                        "top": 0.7872023809523809,
                        "width": 0.5866013071895425,
                        "height": 0.07341269841269842,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.08279160892262179,
                        "top": 0.8650077335418217,
                        "width": 0.13097883984933492,
                        "height": 0.07852306063213045,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.46568627450980393,
                        "top": 0.9037698412698413,
                        "width": 0.008986928104575163,
                        "height": 0.005456349206349206,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.6289296368368311,
                        "top": 0.889411865718781,
                        "width": 0.05064182655484069,
                        "height": 0.02953150915721106,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.7159576416015625,
                        "top": 0.8764419555664062,
                        "width": 0.10577198103362438,
                        "height": 0.0641952393546937,
                        "page": 12,
                        "original_page": 12
                    }
                ]
            },
            {
                "page_range": [
                    15,
                    15
                ],
                "bboxes": [
                    {
                        "left": 0.23039215686274508,
                        "top": 0.07192460317460317,
                        "width": 0.5825163398692811,
                        "height": 0.1701388888888889,
                        "page": 15,
                        "original_page": 15
                    }
                ]
            }
        ]
    },
    "unitCondition": {
        "value": UnitCondition.WARMSHELL,
        "sources": [
            {
                "page_range": [
                    26,
                    26
                ],
                "bboxes": [
                    {
                        "left": 0.15359477124183007,
                        "top": 0.41121031746031744,
                        "width": 0.6356209150326797,
                        "height": 0.1840277777777778,
                        "page": 26,
                        "original_page": 26
                    },
                    {
                        "left": 0.15013403051039753,
                        "top": 0.5359164646693638,
                        "width": 0.235655429316502,
                        "height": 0.18876750885494173,
                        "page": 26,
                        "original_page": 26
                    }
                ]
            }
        ]
    },
    "unitWithFitOuts": {
        "value": null,
        "sources": []
    },
    "furnishedRate": {
        "value": null,
        "sources": []
    },
    "rentFreePeriod": {
        "value": 6,
        "sources": [
            {
                "page_range": [
                    8,
                    8
                ],
                "bboxes": [
                    {
                        "left": 0.21650326797385622,
                        "top": 0.09970238095238096,
                        "width": 0.5866013071895425,
                        "height": 0.24950396825396826,
                        "page": 8,
                        "original_page": 8
                    }
                ]
            }
        ]
    },
    "securityDeposit": {
        "value": 179340000,
        "sources": [
            {
                "page_range": [
                    12,
                    12
                ],
                "bboxes": [
                    {
                        "left": 0.15441176470588236,
                        "top": 0.28621031746031744,
                        "width": 0.636437908496732,
                        "height": 0.12053571428571429,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.15196078431372548,
                        "top": 0.4290674603174603,
                        "width": 0.6372549019607843,
                        "height": 0.07093253968253968,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.1511437908496732,
                        "top": 0.5213293650793651,
                        "width": 0.016339869281045753,
                        "height": 0.006944444444444444,
                        "page": 12,
                        "original_page": 12
                    }
                ]
            },
            {
                "page_range": [
                    40,
                    42
                ],
                "bboxes": [
                    {
                        "left": 0.0737802530425826,
                        "top": 0.6339757404630146,
                        "width": 0.2276814092997632,
                        "height": 0.1382259792751736,
                        "page": 40,
                        "original_page": 40
                    },
                    {
                        "left": 0.0543462092580359,
                        "top": 0.7700713626922123,
                        "width": 0.2931668773975248,
                        "height": 0.1828792511470734,
                        "page": 40,
                        "original_page": 40
                    },
                    {
                        "left": 0.6604035321403953,
                        "top": 0.7621485392252604,
                        "width": 0.10626016255297692,
                        "height": 0.06557143680633061,
                        "page": 40,
                        "original_page": 40
                    },
                    {
                        "left": 0.46078431372549017,
                        "top": 0.9017857142857143,
                        "width": 0.018790849673202614,
                        "height": 0.006448412698412698,
                        "page": 40,
                        "original_page": 40
                    },
                    {
                        "left": 0.16993464052287582,
                        "top": 0.10119047619047619,
                        "width": 0.37663398692810457,
                        "height": 0.015376984126984126,
                        "page": 41,
                        "original_page": 41
                    },
                    {
                        "left": 0.1715686274509804,
                        "top": 0.12251984126984126,
                        "width": 0.6511437908496732,
                        "height": 0.24454365079365079,
                        "page": 41,
                        "original_page": 41
                    },
                    {
                        "left": 0.26703930524439595,
                        "top": 0.5382667420402406,
                        "width": 0.1325634725732741,
                        "height": 0.08158377995566717,
                        "page": 41,
                        "original_page": 41
                    },
                    {
                        "left": 0.4781870125165952,
                        "top": 0.4630785745287698,
                        "width": 0.48143234751582925,
                        "height": 0.41036460513160344,
                        "page": 41,
                        "original_page": 41
                    },
                    {
                        "left": 0.49019607843137253,
                        "top": 0.871031746031746,
                        "width": 0.016339869281045753,
                        "height": 0.013888888888888888,
                        "page": 41,
                        "original_page": 41
                    },
                    {
                        "left": 0.3562091503267974,
                        "top": 0.10218253968253968,
                        "width": 0.2426470588235294,
                        "height": 0.02628968253968254,
                        "page": 42,
                        "original_page": 42
                    },
                    {
                        "left": 0.1576797385620915,
                        "top": 0.15128968253968253,
                        "width": 0.6372549019607843,
                        "height": 0.027281746031746032,
                        "page": 42,
                        "original_page": 42
                    }
                ]
            }
        ]
    },
    "marketValue": {
        "value": 1417143759,
        "sources": [
            {
                "page_range": [
                    1,
                    1
                ],
                "bboxes": [
                    {
                        "left": 0.05065359477124183,
                        "top": 0.03373015873015873,
                        "width": 0.9076797385620915,
                        "height": 0.6011904761904762,
                        "page": 1,
                        "original_page": 1
                    }
                ]
            }
        ]
    },
    "stampDutyAmount": {
        "value": 463000,
        "sources": [
            {
                "page_range": [
                    3,
                    3
                ],
                "bboxes": [
                    {
                        "left": 0.28022875816993464,
                        "top": 0.08928571428571429,
                        "width": 0.42892156862745096,
                        "height": 0.01984126984126984,
                        "page": 3,
                        "original_page": 3
                    },
                    {
                        "left": 0.05310457516339869,
                        "top": 0.11607142857142858,
                        "width": 0.880718954248366,
                        "height": 0.2390873015873016,
                        "page": 3,
                        "original_page": 3
                    }
                ]
            },
            {
                "page_range": [
                    69,
                    69
                ],
                "bboxes": [
                    {
                        "left": 0.1503267973856209,
                        "top": 0.03125,
                        "width": 0.6151960784313726,
                        "height": 0.046130952380952384,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.05065359477124183,
                        "top": 0.10317460317460317,
                        "width": 0.5024509803921569,
                        "height": 0.07787698412698413,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.46405228758169936,
                        "top": 0.10863095238095238,
                        "width": 0.30637254901960786,
                        "height": 0.051587301587301584,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.05555555555555555,
                        "top": 0.19593253968253968,
                        "width": 0.5964052287581699,
                        "height": 0.029265873015873016,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.05800653594771242,
                        "top": 0.24206349206349206,
                        "width": 0.09640522875816994,
                        "height": 0.0248015873015873,
                        "page": 69,
                        "original_page": 69
                    }
                ]
            },
            {
                "page_range": [
                    69,
                    70
                ],
                "bboxes": [
                    {
                        "left": 0.1919934640522876,
                        "top": 0.21626984126984128,
                        "width": 0.5808823529411765,
                        "height": 0.07886904761904762,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.059640522875817,
                        "top": 0.2986111111111111,
                        "width": 0.6862745098039216,
                        "height": 0.09375,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.036518368066525926,
                        "top": 0.40847911531963044,
                        "width": 0.2542090104296317,
                        "height": 0.11437564425998265,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.05310457516339869,
                        "top": 0.5287698412698413,
                        "width": 0.8978758169934641,
                        "height": 0.052083333333333336,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.3214109333512051,
                        "top": 0.6635828775072855,
                        "width": 0.2280039569131689,
                        "height": 0.13616016932896205,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.582526462529999,
                        "top": 0.6644446357848153,
                        "width": 0.28015141705282376,
                        "height": 0.10952661907862103,
                        "page": 69,
                        "original_page": 69
                    },
                    {
                        "left": 0.31731923421223956,
                        "top": 0.19365333375476657,
                        "width": 0.06492467643388736,
                        "height": 0.03634834289550781,
                        "page": 70,
                        "original_page": 70
                    },
                    {
                        "left": 0.3872549019607843,
                        "top": 0.19345238095238096,
                        "width": 0.31290849673202614,
                        "height": 0.03720238095238095,
                        "page": 70,
                        "original_page": 70
                    }
                ]
            },
            {
                "page_range": [
                    72,
                    72
                ],
                "bboxes": [
                    {
                        "left": 0.3835925931244894,
                        "top": 0.5027891794840494,
                        "width": 0.22944795695784825,
                        "height": 0.1274179428342789,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.08496732026143791,
                        "top": 0.5719246031746031,
                        "width": 0.9076797385620915,
                        "height": 0.21279761904761904,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.09313725490196079,
                        "top": 0.783234126984127,
                        "width": 0.4542483660130719,
                        "height": 0.013392857142857142,
                        "page": 72,
                        "original_page": 72
                    }
                ]
            },
            {
                "page_range": [
                    73,
                    73
                ],
                "bboxes": [
                    {
                        "left": 0.3684640522875817,
                        "top": 0.5555555555555556,
                        "width": 0.05228758169934641,
                        "height": 0.023809523809523808,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.05473856209150327,
                        "top": 0.5746031746031746,
                        "width": 0.9158496732026143,
                        "height": 0.2165079365079365,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.06699346405228758,
                        "top": 0.7923809523809524,
                        "width": 0.4673202614379085,
                        "height": 0.009206349206349206,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.06944444444444445,
                        "top": 0.8247619047619048,
                        "width": 0.5196078431372549,
                        "height": 0.028888888888888888,
                        "page": 73,
                        "original_page": 73
                    }
                ]
            }
        ]
    },
    "registrationAmount": {
        "value": 30000,
        "sources": [
            {
                "page_range": [
                    2,
                    2
                ],
                "bboxes": [
                    {
                        "left": 0.2908496732026144,
                        "top": 0.09176587301587301,
                        "width": 0.42892156862745096,
                        "height": 0.015873015873015872,
                        "page": 2,
                        "original_page": 2
                    },
                    {
                        "left": 0.06290849673202614,
                        "top": 0.1130952380952381,
                        "width": 0.8766339869281046,
                        "height": 0.26884920634920634,
                        "page": 2,
                        "original_page": 2
                    }
                ]
            },
            {
                "page_range": [
                    67,
                    68
                ],
                "bboxes": [
                    {
                        "left": 0.05718954248366013,
                        "top": 0.21478174603174602,
                        "width": 0.7165032679738562,
                        "height": 0.08035714285714286,
                        "page": 67,
                        "original_page": 67
                    },
                    {
                        "left": 0.059640522875817,
                        "top": 0.2986111111111111,
                        "width": 0.6854575163398693,
                        "height": 0.09424603174603174,
                        "page": 67,
                        "original_page": 67
                    },
                    {
                        "left": 0.03464924432093801,
                        "top": 0.4076282259017702,
                        "width": 0.255486606772429,
                        "height": 0.11350162445552765,
                        "page": 67,
                        "original_page": 67
                    },
                    {
                        "left": 0.05392156862745098,
                        "top": 0.5287698412698413,
                        "width": 0.8978758169934641,
                        "height": 0.06597222222222222,
                        "page": 67,
                        "original_page": 67
                    },
                    {
                        "left": 0.33560415031084045,
                        "top": 0.625603267124721,
                        "width": 0.22573563320184845,
                        "height": 0.13520050048828125,
                        "page": 67,
                        "original_page": 67
                    },
                    {
                        "left": 0.5768233155892566,
                        "top": 0.6532543727329799,
                        "width": 0.2836902094822304,
                        "height": 0.10862725878518725,
                        "page": 67,
                        "original_page": 67
                    },
                    {
                        "left": 0.15507289163427415,
                        "top": 0.48601226201133124,
                        "width": 0.22873175377939262,
                        "height": 0.13793603200761098,
                        "page": 68,
                        "original_page": 68
                    },
                    {
                        "left": 0.056899320066364764,
                        "top": 0.6512043059818329,
                        "width": 0.29056170719121793,
                        "height": 0.11484848506866939,
                        "page": 68,
                        "original_page": 68
                    }
                ]
            },
            {
                "page_range": [
                    70,
                    70
                ],
                "bboxes": [
                    {
                        "left": 0.33169934640522875,
                        "top": 0.2390873015873016,
                        "width": 0.3545751633986928,
                        "height": 0.01488095238095238,
                        "page": 70,
                        "original_page": 70
                    },
                    {
                        "left": 0.18382352941176472,
                        "top": 0.26884920634920634,
                        "width": 0.22794117647058823,
                        "height": 0.010912698412698412,
                        "page": 70,
                        "original_page": 70
                    },
                    {
                        "left": 0.5408496732026143,
                        "top": 0.26240079365079366,
                        "width": 0.20179738562091504,
                        "height": 0.010912698412698412,
                        "page": 70,
                        "original_page": 70
                    },
                    {
                        "left": 0.184640522875817,
                        "top": 0.28521825396825395,
                        "width": 0.6405228758169934,
                        "height": 0.05357142857142857,
                        "page": 70,
                        "original_page": 70
                    },
                    {
                        "left": 0.4444444444444444,
                        "top": 0.3923611111111111,
                        "width": 0.14624183006535948,
                        "height": 0.014384920634920634,
                        "page": 70,
                        "original_page": 70
                    },
                    {
                        "left": 0.6052960165185866,
                        "top": 0.32756917438809835,
                        "width": 0.13152014040479473,
                        "height": 0.08116037883455791,
                        "page": 70,
                        "original_page": 70
                    },
                    {
                        "left": 0.17320261437908496,
                        "top": 0.4072420634920635,
                        "width": 0.6854575163398693,
                        "height": 0.10168650793650794,
                        "page": 70,
                        "original_page": 70
                    },
                    {
                        "left": 0.19526143790849673,
                        "top": 0.5138888888888888,
                        "width": 0.4665032679738562,
                        "height": 0.015376984126984126,
                        "page": 70,
                        "original_page": 70
                    },
                    {
                        "left": 0.3331380607255923,
                        "top": 0.6146094912574405,
                        "width": 0.5174132702397365,
                        "height": 0.16267855205233134,
                        "page": 70,
                        "original_page": 70
                    }
                ]
            },
            {
                "page_range": [
                    72,
                    72
                ],
                "bboxes": [
                    {
                        "left": 0.3835925931244894,
                        "top": 0.5027891794840494,
                        "width": 0.22944795695784825,
                        "height": 0.1274179428342789,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.08496732026143791,
                        "top": 0.5719246031746031,
                        "width": 0.9076797385620915,
                        "height": 0.21279761904761904,
                        "page": 72,
                        "original_page": 72
                    },
                    {
                        "left": 0.09313725490196079,
                        "top": 0.783234126984127,
                        "width": 0.4542483660130719,
                        "height": 0.013392857142857142,
                        "page": 72,
                        "original_page": 72
                    }
                ]
            },
            {
                "page_range": [
                    73,
                    73
                ],
                "bboxes": [
                    {
                        "left": 0.3684640522875817,
                        "top": 0.5555555555555556,
                        "width": 0.05228758169934641,
                        "height": 0.023809523809523808,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.05473856209150327,
                        "top": 0.5746031746031746,
                        "width": 0.9158496732026143,
                        "height": 0.2165079365079365,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.06699346405228758,
                        "top": 0.7923809523809524,
                        "width": 0.4673202614379085,
                        "height": 0.009206349206349206,
                        "page": 73,
                        "original_page": 73
                    },
                    {
                        "left": 0.06944444444444445,
                        "top": 0.8247619047619048,
                        "width": 0.5196078431372549,
                        "height": 0.028888888888888888,
                        "page": 73,
                        "original_page": 73
                    }
                ]
            }
        ]
    },
    "carParkingSlots": {
        "value": 32,
        "sources": [
            {
                "page_range": [
                    4,
                    4
                ],
                "bboxes": [
                    {
                        "left": 0.1053921568627451,
                        "top": 0.06101190476190476,
                        "width": 0.7655228758169934,
                        "height": 0.6329365079365079,
                        "page": 4,
                        "original_page": 4
                    }
                ]
            },
            {
                "page_range": [
                    8,
                    8
                ],
                "bboxes": [
                    {
                        "left": 0.16013071895424835,
                        "top": 0.3695436507936508,
                        "width": 0.19852941176470587,
                        "height": 0.009424603174603174,
                        "page": 8,
                        "original_page": 8
                    },
                    {
                        "left": 0.21568627450980393,
                        "top": 0.39831349206349204,
                        "width": 0.5800653594771242,
                        "height": 0.041666666666666664,
                        "page": 8,
                        "original_page": 8
                    },
                    {
                        "left": 0.21486928104575165,
                        "top": 0.46279761904761907,
                        "width": 0.5816993464052288,
                        "height": 0.07390873015873016,
                        "page": 8,
                        "original_page": 8
                    },
                    {
                        "left": 0.21323529411764705,
                        "top": 0.5550595238095238,
                        "width": 0.5816993464052288,
                        "height": 0.042162698412698416,
                        "page": 8,
                        "original_page": 8
                    }
                ]
            }
        ]
    },
    "carParkingType": {
        "value": CarParkingType.COVERED,
        "sources": [
            {
                "page_range": [
                    5,
                    6
                ],
                "bboxes": [
                    {
                        "left": 0.17647058823529413,
                        "top": 0.7966269841269841,
                        "width": 0.636437908496732,
                        "height": 0.02628968253968254,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.15020111183715024,
                        "top": 0.8445979527064732,
                        "width": 0.13207651275435303,
                        "height": 0.08065529475136408,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.38132748572654973,
                        "top": 0.828873043968564,
                        "width": 0.2876784630071104,
                        "height": 0.11042325458829365,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.7604768042470894,
                        "top": 0.8320226517934648,
                        "width": 0.16923722410513684,
                        "height": 0.06536332387772817,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.2107843137254902,
                        "top": 0.09970238095238096,
                        "width": 0.5923202614379085,
                        "height": 0.04017857142857143,
                        "page": 6,
                        "original_page": 6
                    }
                ]
            },
            {
                "page_range": [
                    8,
                    8
                ],
                "bboxes": [
                    {
                        "left": 0.16013071895424835,
                        "top": 0.3695436507936508,
                        "width": 0.19852941176470587,
                        "height": 0.009424603174603174,
                        "page": 8,
                        "original_page": 8
                    },
                    {
                        "left": 0.21568627450980393,
                        "top": 0.39831349206349204,
                        "width": 0.5800653594771242,
                        "height": 0.041666666666666664,
                        "page": 8,
                        "original_page": 8
                    },
                    {
                        "left": 0.21486928104575165,
                        "top": 0.46279761904761907,
                        "width": 0.5816993464052288,
                        "height": 0.07390873015873016,
                        "page": 8,
                        "original_page": 8
                    },
                    {
                        "left": 0.21323529411764705,
                        "top": 0.5550595238095238,
                        "width": 0.5816993464052288,
                        "height": 0.042162698412698416,
                        "page": 8,
                        "original_page": 8
                    }
                ]
            }
        ]
    },
    "carParkingCharge": {
        "value": 0,
        "sources": [
            {
                "page_range": [
                    8,
                    8
                ],
                "bboxes": [
                    {
                        "left": 0.16013071895424835,
                        "top": 0.3695436507936508,
                        "width": 0.19852941176470587,
                        "height": 0.009424603174603174,
                        "page": 8,
                        "original_page": 8
                    },
                    {
                        "left": 0.21568627450980393,
                        "top": 0.39831349206349204,
                        "width": 0.5800653594771242,
                        "height": 0.041666666666666664,
                        "page": 8,
                        "original_page": 8
                    },
                    {
                        "left": 0.21486928104575165,
                        "top": 0.46279761904761907,
                        "width": 0.5816993464052288,
                        "height": 0.07390873015873016,
                        "page": 8,
                        "original_page": 8
                    },
                    {
                        "left": 0.21323529411764705,
                        "top": 0.5550595238095238,
                        "width": 0.5816993464052288,
                        "height": 0.042162698412698416,
                        "page": 8,
                        "original_page": 8
                    }
                ]
            }
        ]
    },
    "twoWheelerSlots": {
        "value": null,
        "sources": [
            {
                "page_range": [
                    8,
                    8
                ],
                "bboxes": [
                    {
                        "left": 0.16013071895424835,
                        "top": 0.3695436507936508,
                        "width": 0.19852941176470587,
                        "height": 0.009424603174603174,
                        "page": 8,
                        "original_page": 8
                    },
                    {
                        "left": 0.21568627450980393,
                        "top": 0.39831349206349204,
                        "width": 0.5800653594771242,
                        "height": 0.041666666666666664,
                        "page": 8,
                        "original_page": 8
                    },
                    {
                        "left": 0.21486928104575165,
                        "top": 0.46279761904761907,
                        "width": 0.5816993464052288,
                        "height": 0.07390873015873016,
                        "page": 8,
                        "original_page": 8
                    },
                    {
                        "left": 0.21323529411764705,
                        "top": 0.5550595238095238,
                        "width": 0.5816993464052288,
                        "height": 0.042162698412698416,
                        "page": 8,
                        "original_page": 8
                    }
                ]
            }
        ]
    },
    "twoWheelerCharge": {
        "value": null,
        "sources": [
            {
                "page_range": [
                    8,
                    8
                ],
                "bboxes": [
                    {
                        "left": 0.16013071895424835,
                        "top": 0.3695436507936508,
                        "width": 0.19852941176470587,
                        "height": 0.009424603174603174,
                        "page": 8,
                        "original_page": 8
                    },
                    {
                        "left": 0.21568627450980393,
                        "top": 0.39831349206349204,
                        "width": 0.5800653594771242,
                        "height": 0.041666666666666664,
                        "page": 8,
                        "original_page": 8
                    },
                    {
                        "left": 0.21486928104575165,
                        "top": 0.46279761904761907,
                        "width": 0.5816993464052288,
                        "height": 0.07390873015873016,
                        "page": 8,
                        "original_page": 8
                    },
                    {
                        "left": 0.21323529411764705,
                        "top": 0.5550595238095238,
                        "width": 0.5816993464052288,
                        "height": 0.042162698412698416,
                        "page": 8,
                        "original_page": 8
                    }
                ]
            }
        ]
    },
    "camCharge": {
        "value": 25,
        "sources": [
            {
                "page_range": [
                    12,
                    12
                ],
                "bboxes": [
                    {
                        "left": 0.20751633986928106,
                        "top": 0.5213293650793651,
                        "width": 0.0923202614379085,
                        "height": 0.008432539682539682,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.0941723405925277,
                        "top": 0.542098151312934,
                        "width": 0.2191973355860492,
                        "height": 0.13623004489474827,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.21405228758169934,
                        "top": 0.5530753968253969,
                        "width": 0.576797385620915,
                        "height": 0.10466269841269842,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.15359477124183007,
                        "top": 0.6780753968253969,
                        "width": 0.6356209150326797,
                        "height": 0.05853174603174603,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.06372549019607843,
                        "top": 0.7192460317460317,
                        "width": 0.3374183006535948,
                        "height": 0.09027777777777778,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.20261437908496732,
                        "top": 0.7872023809523809,
                        "width": 0.5866013071895425,
                        "height": 0.07341269841269842,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.08279160892262179,
                        "top": 0.8650077335418217,
                        "width": 0.13097883984933492,
                        "height": 0.07852306063213045,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.46568627450980393,
                        "top": 0.9037698412698413,
                        "width": 0.008986928104575163,
                        "height": 0.005456349206349206,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.6289296368368311,
                        "top": 0.889411865718781,
                        "width": 0.05064182655484069,
                        "height": 0.02953150915721106,
                        "page": 12,
                        "original_page": 12
                    },
                    {
                        "left": 0.7159576416015625,
                        "top": 0.8764419555664062,
                        "width": 0.10577198103362438,
                        "height": 0.0641952393546937,
                        "page": 12,
                        "original_page": 12
                    }
                ]
            }
        ]
    },
    "camPaidBy": {
        "value": LiabilityType.TENANT,
        "sources": [
            {
                "page_range": [

                    13,
                    13
                ],
                "bboxes": [
                    {
                        "left": 0.1772875816993464,
                        "top": 0.2604166666666667,
                        "width": 0.6633986928104575,
                        "height": 0.29464285714285715,
                        "page": 13,
                        "original_page": 13
                    }
                ]
            },
            {
                "page_range": [
                    13,
                    13
                ],
                "bboxes": [
                    {
                        "left": 0.23039215686274508,
                        "top": 0.07142857142857142,
                        "width": 0.5825163398692811,
                        "height": 0.07490079365079365,
                        "page": 13,
                        "original_page": 13
                    },
                    {
                        "left": 0.17647058823529413,
                        "top": 0.16666666666666666,
                        "width": 0.6372549019607843,
                        "height": 0.07390873015873016,
                        "page": 13,
                        "original_page": 13
                    }
                ]
            },
            {
                "page_range": [
                    14,
                    14
                ],
                "bboxes": [
                    {
                        "left": 0.2099673202614379,
                        "top": 0.11408730158730158,
                        "width": 0.5816993464052288,
                        "height": 0.07490079365079365,
                        "page": 14,
                        "original_page": 14
                    },
                    {
                        "left": 0.15441176470588236,
                        "top": 0.2088293650793651,
                        "width": 0.6372549019607843,
                        "height": 0.09077380952380952,
                        "page": 14,
                        "original_page": 14
                    },
                    {
                        "left": 0.1527777777777778,
                        "top": 0.31994047619047616,
                        "width": 0.19444444444444445,
                        "height": 0.009424603174603174,
                        "page": 14,
                        "original_page": 14
                    },
                    {
                        "left": 0.20669934640522875,
                        "top": 0.3521825396825397,
                        "width": 0.5841503267973857,
                        "height": 0.057539682539682536,
                        "page": 14,
                        "original_page": 14
                    }
                ]
            }
        ]
    },
    "totalPropertyTax": {
        "value": null,
        "sources": [
            {
                "page_range": [
                    14,
                    14
                ],
                "bboxes": [
                    {
                        "left": 0.1388888888888889,
                        "top": 0.7132936507936508,
                        "width": 0.6519607843137255,
                        "height": 0.08035714285714286,
                        "page": 14,
                        "original_page": 14
                    },
                    {
                        "left": 0.20833333333333334,
                        "top": 0.8070436507936508,
                        "width": 0.11437908496732026,
                        "height": 0.00744047619047619,
                        "page": 14,
                        "original_page": 14
                    },
                    {
                        "left": 0.20915032679738563,
                        "top": 0.8377976190476191,
                        "width": 0.5808823529411765,
                        "height": 0.025297619047619048,
                        "page": 14,
                        "original_page": 14
                    },
                    {
                        "left": 0.07224602792777267,
                        "top": 0.8683891296386719,
                        "width": 0.13153907676148258,
                        "height": 0.08015956575908358,
                        "page": 14,
                        "original_page": 14
                    },
                    {
                        "left": 0.4632352941176471,
                        "top": 0.902281746031746,
                        "width": 0.016339869281045753,
                        "height": 0.005952380952380952,
                        "page": 14,
                        "original_page": 14
                    },
                    {
                        "left": 0.6381039588279973,
                        "top": 0.8830513727097284,
                        "width": 0.04582054786432802,
                        "height": 0.024370587061321926,
                        "page": 14,
                        "original_page": 14
                    },
                    {
                        "left": 0.7104090771643944,
                        "top": 0.8698043823242188,
                        "width": 0.10546615700316585,
                        "height": 0.06375079684787327,
                        "page": 14,
                        "original_page": 14
                    }
                ]
            },
            {
                "page_range": [
                    15,
                    15
                ],
                "bboxes": [
                    {
                        "left": 0.23039215686274508,
                        "top": 0.07192460317460317,
                        "width": 0.5825163398692811,
                        "height": 0.1701388888888889,
                        "page": 15,
                        "original_page": 15
                    }
                ]
            }
        ]
    },
    "propertyTax": {
        "value": null,
        "sources": [
            {
                "page_range": [
                    14,
                    14
                ],
                "bboxes": [
                    {
                        "left": 0.1388888888888889,
                        "top": 0.7132936507936508,
                        "width": 0.6519607843137255,
                        "height": 0.08035714285714286,
                        "page": 14,
                        "original_page": 14
                    },
                    {
                        "left": 0.20833333333333334,
                        "top": 0.8070436507936508,
                        "width": 0.11437908496732026,
                        "height": 0.00744047619047619,
                        "page": 14,
                        "original_page": 14
                    },
                    {
                        "left": 0.20915032679738563,
                        "top": 0.8377976190476191,
                        "width": 0.5808823529411765,
                        "height": 0.025297619047619048,
                        "page": 14,
                        "original_page": 14
                    },
                    {
                        "left": 0.07224602792777267,
                        "top": 0.8683891296386719,
                        "width": 0.13153907676148258,
                        "height": 0.08015956575908358,
                        "page": 14,
                        "original_page": 14
                    },
                    {
                        "left": 0.4632352941176471,
                        "top": 0.902281746031746,
                        "width": 0.016339869281045753,
                        "height": 0.005952380952380952,
                        "page": 14,
                        "original_page": 14
                    },
                    {
                        "left": 0.6381039588279973,
                        "top": 0.8830513727097284,
                        "width": 0.04582054786432802,
                        "height": 0.024370587061321926,
                        "page": 14,
                        "original_page": 14
                    },
                    {
                        "left": 0.7104090771643944,
                        "top": 0.8698043823242188,
                        "width": 0.10546615700316585,
                        "height": 0.06375079684787327,
                        "page": 14,
                        "original_page": 14
                    }
                ]
            },
            {
                "page_range": [
                    15,
                    15
                ],
                "bboxes": [
                    {
                        "left": 0.23039215686274508,
                        "top": 0.07192460317460317,
                        "width": 0.5825163398692811,
                        "height": 0.1701388888888889,
                        "page": 15,
                        "original_page": 15
                    }
                ]
            }
        ]
    },
    "propertyTaxPaidBy": {
        "value": LiabilityType.BOTH,
        "sources": [
            {
                "page_range": [
                    15,
                    15
                ],
                "bboxes": [
                    {
                        "left": 0.23039215686274508,
                        "top": 0.07192460317460317,
                        "width": 0.5825163398692811,
                        "height": 0.1701388888888889,
                        "page": 15,
                        "original_page": 15
                    }
                ]
            }
        ]
    },
    "floorPlan": {
        "value": "",
        "sources": null
    },
    "considerationValue": {
        "value": 14945000,
        "sources": null
    },
    "landlord": {
        "value": "WHISPERING HEIGHTS REAL ESTATE PRIVATE LIMITED",
        "sources": [
            {
                "page_range": [
                    4,
                    5
                ],
                "bboxes": [
                    {
                        "left": 0.23788088131574245,
                        "top": 0.649208250499907,
                        "width": 0.2232623131446589,
                        "height": 0.107147216796875,
                        "page": 4,
                        "original_page": 4
                    },
                    {
                        "left": 0.7336601307189542,
                        "top": 0.7078373015873016,
                        "width": 0.03349673202614379,
                        "height": 0.01636904761904762,
                        "page": 4,
                        "original_page": 4
                    },
                    {
                        "left": 0.5130718954248366,
                        "top": 0.7351190476190477,
                        "width": 0.25735294117647056,
                        "height": 0.026785714285714284,
                        "page": 4,
                        "original_page": 4
                    },
                    {
                        "left": 0.5751633986928104,
                        "top": 0.7797619047619048,
                        "width": 0.1454248366013072,
                        "height": 0.015873015873015872,
                        "page": 4,
                        "original_page": 4
                    },
                    {
                        "left": 0.0016339869281045752,
                        "top": 0.008432539682539682,
                        "width": 0.005718954248366013,
                        "height": 0.005456349206349206,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.35784313725490197,
                        "top": 0.11607142857142858,
                        "width": 0.2540849673202614,
                        "height": 0.011408730158730158,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.16748366013071894,
                        "top": 0.12946428571428573,
                        "width": 0.6552287581699346,
                        "height": 0.04811507936507937,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.8219704222834967,
                        "top": 0.14273909916953434,
                        "width": 0.04332399056627859,
                        "height": 0.02183790055532304,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.8911672355302798,
                        "top": 0.14023450821165054,
                        "width": 0.04330484228196487,
                        "height": 0.01920952872624473,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.45016339869281047,
                        "top": 0.19642857142857142,
                        "width": 0.0718954248366013,
                        "height": 0.008432539682539682,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.16911764705882354,
                        "top": 0.22668650793650794,
                        "width": 0.6380718954248366,
                        "height": 0.09176587301587301,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.4722222222222222,
                        "top": 0.33978174603174605,
                        "width": 0.03431372549019608,
                        "height": 0.006944444444444444,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.1715686274509804,
                        "top": 0.3685515873015873,
                        "width": 0.6380718954248366,
                        "height": 0.09176587301587301,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.17320261437908496,
                        "top": 0.4791666666666667,
                        "width": 0.636437908496732,
                        "height": 0.04265873015873016,
                        "page": 5,
                        "original_page": 5
                    }
                ]
            }
        ]
    },
    "landlordEmail": {
        "value": "info@whispirings.com",
        "sources": null
    },
    "landlordPhone": {
        "value": "+91 98260 00000",
        "sources": null
    },
    
    "tenant": {
        "value": "KKR India Advisors Private Limited",
        "sources": [
            {
                "page_range": [
                    4,
                    5
                ],
                "bboxes": [
                    {
                        "left": 0.23788088131574245,
                        "top": 0.649208250499907,
                        "width": 0.2232623131446589,
                        "height": 0.107147216796875,
                        "page": 4,
                        "original_page": 4
                    },
                    {
                        "left": 0.7336601307189542,
                        "top": 0.7078373015873016,
                        "width": 0.03349673202614379,
                        "height": 0.01636904761904762,
                        "page": 4,
                        "original_page": 4
                    },
                    {
                        "left": 0.5130718954248366,
                        "top": 0.7351190476190477,
                        "width": 0.25735294117647056,
                        "height": 0.026785714285714284,
                        "page": 4,
                        "original_page": 4
                    },
                    {
                        "left": 0.5751633986928104,
                        "top": 0.7797619047619048,
                        "width": 0.1454248366013072,
                        "height": 0.015873015873015872,
                        "page": 4,
                        "original_page": 4
                    },
                    {
                        "left": 0.0016339869281045752,
                        "top": 0.008432539682539682,
                        "width": 0.005718954248366013,
                        "height": 0.005456349206349206,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.35784313725490197,
                        "top": 0.11607142857142858,
                        "width": 0.2540849673202614,
                        "height": 0.011408730158730158,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.16748366013071894,
                        "top": 0.12946428571428573,
                        "width": 0.6552287581699346,
                        "height": 0.04811507936507937,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.8219704222834967,
                        "top": 0.14273909916953434,
                        "width": 0.04332399056627859,
                        "height": 0.02183790055532304,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.8911672355302798,
                        "top": 0.14023450821165054,
                        "width": 0.04330484228196487,
                        "height": 0.01920952872624473,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.45016339869281047,
                        "top": 0.19642857142857142,
                        "width": 0.0718954248366013,
                        "height": 0.008432539682539682,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.16911764705882354,
                        "top": 0.22668650793650794,
                        "width": 0.6380718954248366,
                        "height": 0.09176587301587301,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.4722222222222222,
                        "top": 0.33978174603174605,
                        "width": 0.03431372549019608,
                        "height": 0.006944444444444444,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.1715686274509804,
                        "top": 0.3685515873015873,
                        "width": 0.6380718954248366,
                        "height": 0.09176587301587301,
                        "page": 5,
                        "original_page": 5
                    },
                    {
                        "left": 0.17320261437908496,
                        "top": 0.4791666666666667,
                        "width": 0.636437908496732,
                        "height": 0.04265873015873016,
                        "page": 5,
                        "original_page": 5
                    }
                ]
            }
        ]
    },
    "tenantEmail": {
        "value": "info@kkrindia.com",
        "sources": null
    },
    "tenantPhone": {
        "value": "+91 98260 00000",
        "sources": null
    },
    "landlordRep": {
        "value": "Vinod Rohira",
        "sources": [
            {
                "page_range": [
                    55,
                    55
                ],
                "bboxes": [
                    {
                        "left": 0.14460784313725492,
                        "top": 0.21478174603174602,
                        "width": 0.6560457516339869,
                        "height": 0.022321428571428572,
                        "page": 55,
                        "original_page": 55
                    },
                    {
                        "left": 0.13970588235294118,
                        "top": 0.25,
                        "width": 0.6609477124183006,
                        "height": 0.1378968253968254,
                        "page": 55,
                        "original_page": 55
                    }
                ]
            }
        ]
    },
    "tenantRep": {
        "value": "Farida Karanjia",
        "sources": [
            {
                "page_range": [
                    28,
                    29
                ],
                "bboxes": [
                    {
                        "left": 0.16013071895424835,
                        "top": 0.6979166666666666,
                        "width": 0.11274509803921569,
                        "height": 0.00744047619047619,
                        "page": 28,
                        "original_page": 28
                    },
                    {
                        "left": 0.06209150326797386,
                        "top": 0.7261904761904762,
                        "width": 0.7352941176470589,
                        "height": 0.13690476190476192,
                        "page": 28,
                        "original_page": 28
                    },
                    {
                        "left": 0.1402113297406365,
                        "top": 0.8772513979957217,
                        "width": 0.13120597639894174,
                        "height": 0.08097457885742188,
                        "page": 28,
                        "original_page": 28
                    },
                    {
                        "left": 0.4722222222222222,
                        "top": 0.9027777777777778,
                        "width": 0.01715686274509804,
                        "height": 0.005952380952380952,
                        "page": 28,
                        "original_page": 28
                    },
                    {
                        "left": 0.6168300653594772,
                        "top": 0.8814484126984127,
                        "width": 0.04330065359477124,
                        "height": 0.017361111111111112,
                        "page": 28,
                        "original_page": 28
                    },
                    {
                        "left": 0.7009958504072202,
                        "top": 0.8485499790736607,
                        "width": 0.1050920174791922,
                        "height": 0.06434443640330481,
                        "page": 28,
                        "original_page": 28
                    },
                    {
                        "left": 0.22794117647058823,
                        "top": 0.08878968253968254,
                        "width": 0.5833333333333334,
                        "height": 0.07242063492063493,
                        "page": 29,
                        "original_page": 29
                    },
                    {
                        "left": 0.22875816993464052,
                        "top": 0.18303571428571427,
                        "width": 0.20833333333333334,
                        "height": 0.009424603174603174,
                        "page": 29,
                        "original_page": 29
                    },
                    {
                        "left": 0.22875816993464052,
                        "top": 0.21428571428571427,
                        "width": 0.4338235294117647,
                        "height": 0.011408730158730158,
                        "page": 29,
                        "original_page": 29
                    },
                    {
                        "left": 0.22712418300653595,
                        "top": 0.2296626984126984,
                        "width": 0.33088235294117646,
                        "height": 0.010416666666666666,
                        "page": 29,
                        "original_page": 29
                    },
                    {
                        "left": 0.22794117647058823,
                        "top": 0.2614087301587302,
                        "width": 0.5808823529411765,
                        "height": 0.025793650793650792,
                        "page": 29,
                        "original_page": 29
                    },
                    {
                        "left": 0.1707516339869281,
                        "top": 0.3100198412698413,
                        "width": 0.02287581699346405,
                        "height": 0.006448412698412698,
                        "page": 29,
                        "original_page": 29
                    }
                ]
            }
        ]
    },
    "builtUpArea": {
        "value": 42700,
        "sources": [
            {
                "page_range": [
                    6,
                    6
                ],
                "bboxes": [
                    {
                        "left": 0.1650326797385621,
                        "top": 0.16170634920634921,
                        "width": 0.636437908496732,
                        "height": 0.2013888888888889,
                        "page": 6,
                        "original_page": 6
                    }
                ]
            },
            {
                "page_range": [
                    33,
                    33
                ],
                "bboxes": [
                    {
                        "left": 0.3790849673202614,
                        "top": 0.1865079365079365,
                        "width": 0.28513071895424835,
                        "height": 0.026785714285714284,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17565359477124182,
                        "top": 0.23313492063492064,
                        "width": 0.6462418300653595,
                        "height": 0.18501984126984128,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.332918703166488,
                        "top": 0.3859390379890563,
                        "width": 0.33196448033152065,
                        "height": 0.14046284509083581,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.1781045751633987,
                        "top": 0.439484126984127,
                        "width": 0.6388888888888888,
                        "height": 0.00992063492063492,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17892156862745098,
                        "top": 0.4558531746031746,
                        "width": 0.6380718954248366,
                        "height": 0.00992063492063492,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17892156862745098,
                        "top": 0.47172619047619047,
                        "width": 0.10784313725490197,
                        "height": 0.010912698412698412,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.1781045751633987,
                        "top": 0.501984126984127,
                        "width": 0.6372549019607843,
                        "height": 0.026785714285714284,
                        "page": 33,
                        "original_page": 33
                    }
                ]
            }
        ]
    },
    "carpetArea": {
        "value": 25620,
        "sources": [
            {
                "page_range": [
                    6,
                    6
                ],
                "bboxes": [
                    {
                        "left": 0.1650326797385621,
                        "top": 0.16170634920634921,
                        "width": 0.636437908496732,
                        "height": 0.2013888888888889,
                        "page": 6,
                        "original_page": 6
                    }
                ]
            },
            {
                "page_range": [
                    33,
                    33
                ],
                "bboxes": [
                    {
                        "left": 0.3790849673202614,
                        "top": 0.1865079365079365,
                        "width": 0.28513071895424835,
                        "height": 0.026785714285714284,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17565359477124182,
                        "top": 0.23313492063492064,
                        "width": 0.6462418300653595,
                        "height": 0.18501984126984128,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.332918703166488,
                        "top": 0.3859390379890563,
                        "width": 0.33196448033152065,
                        "height": 0.14046284509083581,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.1781045751633987,
                        "top": 0.439484126984127,
                        "width": 0.6388888888888888,
                        "height": 0.00992063492063492,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17892156862745098,
                        "top": 0.4558531746031746,
                        "width": 0.6380718954248366,
                        "height": 0.00992063492063492,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.17892156862745098,
                        "top": 0.47172619047619047,
                        "width": 0.10784313725490197,
                        "height": 0.010912698412698412,
                        "page": 33,
                        "original_page": 33
                    },
                    {
                        "left": 0.1781045751633987,
                        "top": 0.501984126984127,
                        "width": 0.6372549019607843,
                        "height": 0.026785714285714284,
                        "page": 33,
                        "original_page": 33
                    }
                ]
            }
        ]
    },
    "landlordLockIn": {
        "value": 120,
        "sources": [
            {
                "page_range": [
                    9,
                    9
                ],
                "bboxes": [
                    {
                        "left": 0.1707516339869281,
                        "top": 0.32142857142857145,
                        "width": 0.3431372549019608,
                        "height": 0.010416666666666666,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.17238562091503268,
                        "top": 0.35168650793650796,
                        "width": 0.636437908496732,
                        "height": 0.05952380952380952,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.17320261437908496,
                        "top": 0.4305555555555556,
                        "width": 0.6380718954248366,
                        "height": 0.08928571428571429,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.7400996887605954,
                        "top": 0.48082000490218874,
                        "width": 0.2257112490585427,
                        "height": 0.1355008322095114,
                        "page": 9,
                        "original_page": 9
                    }
                ]
            },
            {
                "page_range": [
                    9,
                    9
                ],
                "bboxes": [
                    {
                        "left": 0.17401960784313725,
                        "top": 0.5406746031746031,
                        "width": 0.636437908496732,
                        "height": 0.13541666666666666,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.17483660130718953,
                        "top": 0.6944444444444444,
                        "width": 0.6070261437908496,
                        "height": 0.028273809523809524,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.17320261437908496,
                        "top": 0.7420634920634921,
                        "width": 0.5408496732026143,
                        "height": 0.008928571428571428,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.6920440773558773,
                        "top": 0.6887989952450707,
                        "width": 0.2898099313374438,
                        "height": 0.10211387513175843,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.2295751633986928,
                        "top": 0.7733134920634921,
                        "width": 0.23039215686274508,
                        "height": 0.009424603174603174,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.17401960784313725,
                        "top": 0.7891865079365079,
                        "width": 0.6388888888888888,
                        "height": 0.041666666666666664,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.11987754722046697,
                        "top": 0.8514087616451202,
                        "width": 0.12968121946247574,
                        "height": 0.0801284426734561,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.48856209150326796,
                        "top": 0.8759920634920635,
                        "width": 0.008169934640522876,
                        "height": 0.005952380952380952,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.6600535772984324,
                        "top": 0.8953418428935702,
                        "width": 0.03873014761731516,
                        "height": 0.023485577295696926,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.7506435618681067,
                        "top": 0.8583999512687562,
                        "width": 0.10775118559793709,
                        "height": 0.06413765559120783,
                        "page": 9,
                        "original_page": 9
                    }
                ]
            },
            {
                "page_range": [
                    25,
                    25
                ],
                "bboxes": [
                    {
                        "left": 0.1781045751633987,
                        "top": 0.35367063492063494,
                        "width": 0.6356209150326797,
                        "height": 0.05853174603174603,
                        "page": 25,
                        "original_page": 25
                    },
                    {
                        "left": 0.23366013071895425,
                        "top": 0.4320436507936508,
                        "width": 0.5816993464052288,
                        "height": 0.025297619047619048,
                        "page": 25,
                        "original_page": 25
                    },
                    {
                        "left": 0.23366013071895425,
                        "top": 0.47966269841269843,
                        "width": 0.6094771241830066,
                        "height": 0.1195436507936508,
                        "page": 25,
                        "original_page": 25
                    },
                    {
                        "left": 0.7377605064242494,
                        "top": 0.5027100396534753,
                        "width": 0.22565659666373059,
                        "height": 0.13667451767694383,
                        "page": 25,
                        "original_page": 25
                    }
                ]
            }
        ]
    },
    "tenantLockIn": {
        "value": 60,
        "sources": [
            {
                "page_range": [
                    9,
                    9
                ],
                "bboxes": [
                    {
                        "left": 0.1707516339869281,
                        "top": 0.32142857142857145,
                        "width": 0.3431372549019608,
                        "height": 0.010416666666666666,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.17238562091503268,
                        "top": 0.35168650793650796,
                        "width": 0.636437908496732,
                        "height": 0.05952380952380952,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.17320261437908496,
                        "top": 0.4305555555555556,
                        "width": 0.6380718954248366,
                        "height": 0.08928571428571429,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.7400996887605954,
                        "top": 0.48082000490218874,
                        "width": 0.2257112490585427,
                        "height": 0.1355008322095114,
                        "page": 9,
                        "original_page": 9
                    }
                ]
            },
            {
                "page_range": [
                    9,
                    9
                ],
                "bboxes": [
                    {
                        "left": 0.17401960784313725,
                        "top": 0.5406746031746031,
                        "width": 0.636437908496732,
                        "height": 0.13541666666666666,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.17483660130718953,
                        "top": 0.6944444444444444,
                        "width": 0.6070261437908496,
                        "height": 0.028273809523809524,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.17320261437908496,
                        "top": 0.7420634920634921,
                        "width": 0.5408496732026143,
                        "height": 0.008928571428571428,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.6920440773558773,
                        "top": 0.6887989952450707,
                        "width": 0.2898099313374438,
                        "height": 0.10211387513175843,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.2295751633986928,
                        "top": 0.7733134920634921,
                        "width": 0.23039215686274508,
                        "height": 0.009424603174603174,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.17401960784313725,
                        "top": 0.7891865079365079,
                        "width": 0.6388888888888888,
                        "height": 0.041666666666666664,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.11987754722046697,
                        "top": 0.8514087616451202,
                        "width": 0.12968121946247574,
                        "height": 0.0801284426734561,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.48856209150326796,
                        "top": 0.8759920634920635,
                        "width": 0.008169934640522876,
                        "height": 0.005952380952380952,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.6600535772984324,
                        "top": 0.8953418428935702,
                        "width": 0.03873014761731516,
                        "height": 0.023485577295696926,
                        "page": 9,
                        "original_page": 9
                    },
                    {
                        "left": 0.7506435618681067,
                        "top": 0.8583999512687562,
                        "width": 0.10775118559793709,
                        "height": 0.06413765559120783,
                        "page": 9,
                        "original_page": 9
                    }
                ]
            }
        ]
    },
    "securityDepositEquivalent": {
        "value": 12,
        "sources": null
    },
    "rentPerSquareFootMonthly": {
        "value": 350,
        "sources": null
    },
    "superBuiltupArea": {
        "value": null,
        "sources": null
    },
    "landlordRepRole": {
        "value": "Authorized to sign and execute leasing documents on behalf of the company.",
        "sources": [
            {
                "page_range": [
                    55,
                    55
                ],
                "bboxes": [
                    {
                        "left": 0.14460784313725492,
                        "top": 0.21478174603174602,
                        "width": 0.6560457516339869,
                        "height": 0.022321428571428572,
                        "page": 55,
                        "original_page": 55
                    },
                    {
                        "left": 0.13970588235294118,
                        "top": 0.25,
                        "width": 0.6609477124183006,
                        "height": 0.1378968253968254,
                        "page": 55,
                        "original_page": 55
                    }
                ]
            }
        ]
    },
    "tenantRepRole": {
        "value": "Tenant's representative: Negotiates, executes, registers lease.",
        "sources": [
            {
                "page_range": [
                    57,
                    57
                ],
                "bboxes": [
                    {
                        "left": 0.13970588235294118,
                        "top": 0.1984126984126984,
                        "width": 0.7467320261437909,
                        "height": 0.09722222222222222,
                        "page": 57,
                        "original_page": 57
                    },
                    {
                        "left": 0.14133986928104575,
                        "top": 0.31200396825396826,
                        "width": 0.7467320261437909,
                        "height": 0.06001984126984127,
                        "page": 57,
                        "original_page": 57
                    }
                ]
            }
        ]
    },
    "monthlyCamCharge": {
        "value": 1067500,
        "sources": null
    },
    "reportSASURL": "https://leaseanalysisb2b.blob.core.windows.net/tmp/report.xlsx?st=2025-02-04T09%3A16%3A22Z&se=2025-02-04T10%3A16%3A22Z&sp=r&sv=2025-01-05&sr=b&sig=zh2j3l1wWjPaluF0IXyYcvo%2BwvQtpuYBnC5nm8VPx/c%3D"
}