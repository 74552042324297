import { Box, Button, CircularProgress, IconButton, Switch, Tooltip, Typography, useTheme } from "@mui/material";
import { CloudUploadOutlined, ZoomIn, ZoomOut } from "@mui/icons-material";
import { VisuallyHiddenInput } from "../lib/utils";
import { AnalysisDataDisplay } from "./AnalysisDataDisplay";
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { AnalysisResult, BBox, Source } from "../lib/interfaces";
import { BlockBlobClient } from "@azure/storage-blob";
import colors from "../lib/colors";
import { useDropzone } from "react-dropzone";
import { getAuth } from "firebase/auth";
import { ORIGIN } from "../App";
import { Document, Page } from "react-pdf";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
// import { data } from "../lib/testData";
import { SidebarWidthContext } from "./Page";

enum View {
    NotUploaded,
    Uploading,
    Processing,
    Preview
}

interface SourcingContextProps {
    navigateToPage: (page: number) => void;
    drawBoundingBoxes: (boundingBoxes: BBox[]) => void;
}

const ZOOM_LEVELS = [0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3, 4, 5];

export const SourcingContext = createContext<SourcingContextProps | null>(null);

export function LeaseAnalysisView() {
    const auth = getAuth();
    const theme = useTheme();
    const [selectedFile, setSelectedFile] = useState<File>(new File([], "No file selected"));
    const [data, setData] = useState<AnalysisResult>();
    const [view, setView] = useState<View>(View.NotUploaded);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [numPages, setNumPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const pageRefs = useRef<HTMLDivElement[]>([]);
    const sidebarWidth = useContext(SidebarWidthContext);
    const [zoomLevel, setZoomLevel] = useState(7);
    const [boundingBoxRefs, setBoundingBoxRefs] = useState<HTMLDivElement[]>([]);
    const [boundingBoxes, setBoundingBoxes] = useState<BBox[]>([]);
    const [sourceHighlighting, setSourceHighlighting] = useState(true);

    // get refs for all pages, set up observer to track current page
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries: IntersectionObserverEntry[]) => {
                const visiblePage = entries
                    .filter((entry) => entry.isIntersecting)
                    .map((entry) => Number((entry.target as HTMLElement).dataset.page))[0]; // Get the first visible page by filtering for pages on screen and getting the page number

                if (visiblePage) setCurrentPage(visiblePage);
            },
            { root: null, rootMargin: "-50% 0px -50% 0px", threshold: 0 }
        );

        pageRefs.current.forEach((page) => page && observer.observe(page)); // add all pages to the observer

        return () => {
            pageRefs.current.forEach((page) => page && observer.unobserve(page)); // remove all pages from the observer
        };
    }, [numPages]);

    // useEffect(() => {
    //     const handleClickOutside = (event: MouseEvent) => {
    //         console.log("clicked outside");
    //         console.log(event.target);
    //         // Check if the click is inside any bounding box
    //         if (event.target instanceof HTMLElement && (event.target.classList.contains("bounding-box") || event.target.classList.contains("field-display"))) {
    //             return; // Do nothing if clicked inside a bounding box
    //         }

    //         console.log("removing bounding boxes");
    //         console.log(boundingBoxRefs);
    //         // Otherwise, remove all bounding boxes
    //         boundingBoxRefs.forEach((ref) => ref.remove());
    //         setBoundingBoxes([]);
    //         setBoundingBoxRefs([]);
    //     };

    //     document.addEventListener("click", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("click", handleClickOutside);
    //     };
    // }, []);

    async function FetchData(org: string, blobName: string) {
        const requestBody = {
            "organization": org,
            "blobName": blobName
        };
        const idToken = await auth.currentUser?.getIdToken();
        const response = await fetch(ORIGIN + "/analyze-propstack", {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${idToken}`
            },
            // credentials: "include",
            body: JSON.stringify(requestBody)
        });
        response.json().then((result) => {
            setData(result);
        })
            .catch((error) => {
                alert("Failed to fetch data");
                setView(View.NotUploaded);
            });
    }

    async function handleFileChanged(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files === null || event.target.files.length === 0) return;
        FileChosen(event.target.files[0])
    }

    async function UploadFile(file: File) {
        if (auth.currentUser === null) return "";
        const response = await fetch(`https://leaseanalysisfunctions.azurewebsites.net/api/fetch_demo_sas?blobName=${auth.currentUser.uid}/${encodeURIComponent(file.name)}`, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
            },
        });
        const SAS = await response.json();
        const blockBlobClient = new BlockBlobClient(SAS);
        try {
            await blockBlobClient.uploadData(file);
            return blockBlobClient.name;
        }
        catch {
            return "";
        }
    }

    async function FileChosen(file: File) {
        if (auth.currentUser === null) return;
        setView(View.Uploading)
        setSelectedFile(file);
        const blobName = await UploadFile(file);
        if (blobName === "") return alert("Upload failed!");
        const url = URL.createObjectURL(file);
        setPreviewUrl(url);
        setView(View.Processing)
        FetchData("tmp", blobName);
        // FetchData("tmp", `${auth.currentUser.uid}/${file.name}`);
        setView(View.Preview);
    }

    const onDrop = useCallback((acceptedFiles: File[]) => {
        // if (acceptedFiles.length == 0) return setUploadErrorText("Invalid file, please upload correct file type.");
        FileChosen(acceptedFiles[0])
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: {
            'application/pdf': [".pdf"],
        },
        // disabled: (uploadState != UploadState.NotUploaded)
    });

    function handleNewAnalysis() {
        setView(View.NotUploaded);
        setData(undefined);
        setPreviewUrl(null);
        setSelectedFile(new File([], "No file selected"));
    }

    function navigateToPage(page: number) {
        setCurrentPage(page);
    }

    function increaseZoom() {
        if (zoomLevel < ZOOM_LEVELS.length - 1) {
            setZoomLevel(zoomLevel + 1);
        }
    }

    function decreaseZoom() {
        if (zoomLevel > 0) {
            setZoomLevel(zoomLevel - 1);
        }
    }

    useEffect(() => {
        drawBoundingBoxes(boundingBoxes);
    }, [zoomLevel]);

    function drawBoundingBoxes(boundingBoxes: BBox[]) {
        boundingBoxRefs.forEach((ref) => ref.remove());
        setBoundingBoxes(boundingBoxes);
        const newBoundingBoxRefs: HTMLDivElement[] = [];
        boundingBoxes.forEach(({ page, left, top, width, height }) => {
            const pageElement = pageRefs.current[page - 1];
            // console.log("pageElement", pageElement);
            // console.log("page", page);
            if (!pageElement) return;


            const { width: pageWidth, height: pageHeight } = pageElement.getBoundingClientRect();
            // console.log(pageWidth, pageHeight);

            const box = document.createElement("div");
            box.classList.add("bounding-box");
            box.style.position = "absolute";
            box.style.left = `${left * pageWidth}px`;
            box.style.top = `${top * pageHeight}px`;
            box.style.width = `${width * pageWidth}px`;
            box.style.height = `${height * pageHeight}px`;
            box.style.border = "2px solid blue"; // Example style
            box.style.backgroundColor = "blue";
            box.style.opacity = "0.1";
            box.style.display = sourceHighlighting ? "block" : "none";

            box.style.boxSizing = "border-box";

            pageElement.appendChild(box);
            newBoundingBoxRefs.push(box);
        });
        // console.log("newBoundingBoxRefs", newBoundingBoxRefs);
        setBoundingBoxRefs(newBoundingBoxRefs);
    }

    function handleSourceHighlightingChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSourceHighlighting(event.target.checked);
        boundingBoxRefs.forEach((box) => {
            box.style.display = event.target.checked ? "block" : "none";
        });
    }

    return (
        <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} height={"100vh"} width={"100%"} bgcolor={colors.SchemesSurfaceContainerLow} overflow={"clip"}>

            {
                view === View.NotUploaded &&
                <Box
                    {...getRootProps()}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent={"center"}
                    gap={theme.spacing(2)}
                    margin={"auto"}
                    sx={{
                        background: 'white',
                        padding: theme.spacing(4),
                        borderRadius: theme.spacing(4),
                        width: "50vw",
                        height: "40vh"
                    }}
                >
                    <CloudUploadOutlined color="primary" sx={{ width: 101, height: 101 }} />
                    <Typography>Drop your file here or</Typography>
                    <VisuallyHiddenInput name="fileHolder" id="fileHolder" type="file" onChange={handleFileChanged} {...getInputProps()} />
                    <Button component="label" variant="contained" color="secondary">
                        Browse
                    </Button>
                    <Typography textAlign="center" variant="body2" color="grey">Accepted file types: .pdf <br /> Longer agreements may take longer to process.</Typography>
                </Box>
            }
            {
                view === View.Uploading &&
                <Box margin="auto" display={"flex"} flexDirection={"column"} alignItems={"center"} bgcolor={"white"} borderRadius={theme.spacing(4)} padding={theme.spacing(16)}>
                    <Typography variant='h3' marginBottom={theme.spacing(4)}>Uploading...</Typography>
                    <CircularProgress />
                </Box>
            }
            {
                (view === View.Preview || view === View.Processing) &&
                <Box display={"flex"} flexDirection={"row"} height={"100%"} width={"calc(100vw - " + sidebarWidth + "px)"} marginLeft={theme.spacing(8)}>
                    <Box
                        margin={theme.spacing(2)}
                        borderRadius={theme.spacing(4)}
                        bgcolor={"white"}
                        padding={theme.spacing(4)}
                        flex={1}
                    >
                        <Box
                            display={"flex"}    
                            flexDirection={"row"}
                            alignItems={"center"}
                            bgcolor={colors.greyDarker}
                            padding={theme.spacing(1)}
                            paddingLeft={theme.spacing(2)}
                            paddingRight={theme.spacing(2)}
                        >
                            <Box flex={1} textAlign={"left"}>
                                <Typography color={colors.greyLightest}>{selectedFile.name}</Typography>
                            </Box>

                            <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"center"} gap={theme.spacing(1)} flex={1} textAlign={"center"}>
                                <Typography color="white">
                                    Page {currentPage} of {numPages}
                                </Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"right"} gap={theme.spacing(1)} flex={1} textAlign={"center"}>
                                <IconButton onClick={decreaseZoom} sx={{ color: "white", padding: 0 }}>
                                    <ZoomOut />
                                </IconButton>
                                <Typography color="white">{Math.round(ZOOM_LEVELS[zoomLevel] * 100)}%</Typography>
                                <IconButton onClick={increaseZoom} sx={{ color: "white", padding: 0 }}>
                                    <ZoomIn />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box
                            height={"100%"}
                            overflow={"auto"}
                            bgcolor={colors.greyDark}
                            padding={theme.spacing(2)}
                            paddingBottom={0}
                            width={"calc((100vw - " + sidebarWidth + "px)/2 - " + theme.spacing(8) + ")"}
                            position={"relative"}
                        >
                            {previewUrl ? (
                                <Document file={previewUrl} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                                    {Array.from({ length: numPages }, (_, i) => (
                                        <Box
                                            data-page={i + 1}
                                            margin={"auto"}
                                            marginBottom={theme.spacing(2)}
                                            ref={(el: HTMLDivElement) => (pageRefs.current[i] = el)}
                                            key={i}
                                            width={"fit-content"}
                                            position={"relative"}
                                            boxShadow={12}
                                        >
                                            <Page key={i} pageNumber={i + 1} renderTextLayer={false} renderAnnotationLayer={false} scale={ZOOM_LEVELS[zoomLevel]} />
                                        </Box>
                                    ))}
                                </Document>
                            ) : (
                                <Typography>Loading preview...</Typography>
                            )}
                        </Box>
                        {/* Fixed Page Indicator */}
                        {/* <Box
                            sx={{
                                position: "relative",
                                top: "-8%",
                                left: "50%",
                                transform: "translateX(-50%)",
                                borderRadius: "5px",
                                zIndex: 1000,
                                boxShadow: 4,
                            }}
                            display="flex"
                            bgcolor={colors.greyDarker}
                            flexDirection="row"
                            alignItems="center"
                            padding={theme.spacing(1)}
                            width={"max-content"}
                            gap={theme.spacing(1)}
                        >
                            <Typography color="white">
                                Page {currentPage} of {numPages}
                            </Typography>
                            <IconButton onClick={decreaseZoom} sx={{ color: "white", padding: 0 }}>
                                <ZoomOut />
                            </IconButton>
                            <Typography color="white">{Math.round(ZOOM_LEVELS[zoomLevel] * 100)}%</Typography>
                            <IconButton onClick={increaseZoom} sx={{ color: "white", padding: 0 }}>
                                <ZoomIn />
                            </IconButton>
                        </Box> */}
                    </Box>
                    <Box flex={1} overflow={"auto"} >
                        {
                            view === View.Processing || data === undefined ?
                                <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} height={"100%"} >
                                    <Typography variant='h3' marginBottom={theme.spacing(4)}>Processing...</Typography>
                                    <CircularProgress />
                                </Box>
                                :
                            <SourcingContext.Provider value={{
                                navigateToPage: (pageNumber: number) => pageRefs.current[pageNumber - 1].scrollIntoView({ behavior: 'smooth' }),
                                drawBoundingBoxes: drawBoundingBoxes
                            }}>
                                <AnalysisDataDisplay data={data} handleNewAnalysis={handleNewAnalysis} sourceHighlighting={sourceHighlighting} handleSourceHighlightingChange={handleSourceHighlightingChange} />
                            </SourcingContext.Provider>

                        }
                    </Box>
                </Box>
            }
        </Box>
    );
}