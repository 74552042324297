import { Box, Tooltip, Typography } from "@mui/material";
import { Result, Source } from "../lib/interfaces";
import { useContext } from "react";
import { SourcingContext } from "./LeaseAnalysisView";

interface FieldDisplayProps<T> {
    field: Result<T>;
}

export function NotFoundText() {
    return <Typography color="DarkGray" fontStyle={"italic"}>Not Found/Not Applicable</Typography>
}

function pageSourceTooltipText(sources: Source[] | null): string {
    if (!sources) return "";
    const pageRanges = sources.map((source) => source.page_range);
    return pageRanges.map((pageRange) => {
        if (pageRange[0] === pageRange[1]) {
            return `Page ${pageRange[0]}`;
        }
        return `Pages ${pageRange[0]} - ${pageRange[1]}`;
    }).join(", ");
}

export function FieldDisplay<T>({ field }: FieldDisplayProps<T>) {
    const navigable = field.sources && field.sources.length > 0 && field.value !== null && field.value !== "";
    const { navigateToPage, drawBoundingBoxes } = useContext(SourcingContext) ?? {};

    function jumpToSource() {
        if (!navigable) return;
        if (field.sources && field.sources.length > 0 && navigateToPage) {
            navigateToPage(field.sources[0].page_range[0]);
            if (drawBoundingBoxes) {
                drawBoundingBoxes(field.sources[0].bboxes);
            }
        }
    }

    return (
        <Box sx={{ cursor: navigable ? "pointer" : "default" }} onClick={jumpToSource} >
            <Tooltip title={(field.value && field.value !== "") ? pageSourceTooltipText(field.sources) : ""}>
                {field.value ? <Typography variant="body1" className="field-display">{field.value as string}</Typography> : <NotFoundText />}
            </Tooltip>
        </Box>
    );
}
