import { Box, Button, ButtonBase, Grid2, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { AnalysisResult, QAAnalysisResult } from "../lib/interfaces";
import { CloudUpload, Download, InsertDriveFileOutlined, Replay } from "@mui/icons-material";
import colors from "../lib/colors";
import { VisuallyHiddenInput } from "../lib/utils";
import React, { useState } from "react";
import { emptyResult } from "../lib/testData";
import { NotFoundText } from "./FieldDisplay";
import { FieldDisplay } from "./FieldDisplay";


interface AnalysisQADataDisplayProps {
    data: AnalysisResult
    handleNewAnalysis: () => void
}

export function AnalysisQADataDisplay({ data, handleNewAnalysis }: AnalysisQADataDisplayProps) {
    const theme = useTheme();
    const [comparison, setComparison] = useState(emptyResult);

    function addComparison(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files === null) return;
        const inputJson: File = event.target.files[0];
        if (inputJson.type !== "application/json") return;
        const reader = new FileReader();
        console.log("hello? outside")

        reader.onload = () => {
            try {
                console.log("hello? inside")
                // Parse JSON content
                const jsonObject = JSON.parse(reader.result as string) as QAAnalysisResult;
                console.log(jsonObject)
                setComparison(jsonObject);
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }
        };

        // Read the file as text
        reader.readAsText(inputJson);
    }

    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} overflow={"auto"} margin={theme.spacing(2)} borderRadius={theme.spacing(4)} bgcolor={"white"} padding={theme.spacing(4)} width={"85%"}>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"} marginBottom={theme.spacing(4)} width={"100%"}>
                <Box flex={1} />
                <Typography flex={1} textAlign={"center"} variant="h4">Lease Analysis</Typography>
                <Box flex={1} display={"flex"} flexDirection={"row"} alignItems={"right"} justifyContent={"flex-end"}>
                    <Tooltip title="Add Comparison">
                        <IconButton
                            component="label"
                        >
                            <CloudUpload
                                sx={{ color: colors.PropayaViolet }}
                            />
                            <VisuallyHiddenInput
                                type="file"
                                onChange={addComparison}
                                multiple={false}
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Download Form">
                        <IconButton
                            href={data.reportSASURL}
                            target="_blank"
                        >
                            <Download
                                sx={{ color: colors.PropayaViolet }}
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="New Analysis">
                        <IconButton onClick={handleNewAnalysis}>
                            <Replay
                                sx={{ color: colors.PropayaViolet }}
                            />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Grid2 container spacing={2} alignItems="center">
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Document Number:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.documentNumber} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.documentNumber ? <Typography variant="body1">{comparison.documentNumber}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Project Name:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.projectName} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.projectName ? <Typography variant="body1">{comparison.projectName}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Plot Number:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.plotNumber} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.plotNumber ? <Typography variant="body1">{comparison.plotNumber}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">District:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.district} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.district ? <Typography variant="body1">{comparison.district}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Village:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.village} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.village ? <Typography variant="body1">{comparison.village}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Khewat:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.khewat} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.khewat ? <Typography variant="body1">{comparison.khewat}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Municipality:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.municipality} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.municipality ? <Typography variant="body1">{comparison.municipality}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Registrar Office:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.registrarOffice} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.registrarOffice ? <Typography variant="body1">{comparison.registrarOffice}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Agreement Type:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.agreementType} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.agreementType ? <Typography variant="body1">{comparison.agreementType}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Registration Date:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.registrationDate} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.registrationDate ? <Typography variant="body1">{comparison.registrationDate}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Landlord:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.landlord} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.landlord ? <Typography variant="body1">{comparison.landlord}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Landlord Representative:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.landlordRep} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.landlordRep ? <Typography variant="body1">{comparison.landlordRep}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Landlord Representative Role:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.landlordRepRole} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.landlordRepRole ? <Typography variant="body1">{comparison.landlordRepRole}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Tenant:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.tenant} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.tenant ? <Typography variant="body1">{comparison.tenant}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Tenant Representative:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.tenantRep} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.tenantRep ? <Typography variant="body1">{comparison.tenantRep}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Tenant Representative Role:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.tenantRepRole} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.tenantRepRole ? <Typography variant="body1">{comparison.tenantRepRole}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Unit Number:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.unit} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.unit ? <Typography variant="body1">{comparison.unit}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Floor Number:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.floor} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.floor ? <Typography variant="body1">{comparison.floor}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Wing:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.wing} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.wing ? <Typography variant="body1">{comparison.wing}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Other Unit Info:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.addressExtraDiscriminator} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.addressExtraDiscriminator ? <Typography variant="body1">{comparison.addressExtraDiscriminator}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Chargeable Area Type:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.chargeableAreaType} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.chargeableAreaType ? <Typography variant="body1">{comparison.chargeableAreaType}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Super Builtup Area:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.superBuiltupArea} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.superBuiltupArea ? <Typography variant="body1">{comparison.superBuiltupArea}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">BuiltUp Area:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.builtUpArea} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.builtUpArea ? <Typography variant="body1">{comparison.builtUpArea}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Carpet Area:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.carpetArea} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.carpetArea ? <Typography variant="body1">{comparison.carpetArea}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Agreement Start Date:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.agreementStartDate} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.agreementStartDate ? <Typography variant="body1">{comparison.agreementStartDate}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Agreement Expiration Date:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.agreementExpirationDate} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.agreementExpirationDate ? <Typography variant="body1">{comparison.agreementExpirationDate}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Agreement Duration:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.agreementDuration} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.agreementDuration ? <Typography variant="body1">{comparison.agreementDuration}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Monthly Rent:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.rentMonthly} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.rentMonthly ? <Typography variant="body1">{comparison.rentMonthly}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Monthly Square Foot Rent:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.rentPerSquareFootMonthly} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.rentPerSquareFootMonthly ? <Typography variant="body1">{comparison.rentPerSquareFootMonthly}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Consideration Value:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.considerationValue} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.considerationValue ? <Typography variant="body1">{comparison.considerationValue}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Escalation Terms:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.escalationTerms} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.escalationTerms ? <Typography variant="body1">{comparison.escalationTerms}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Unit Condition:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.unitCondition} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.unitCondition ? <Typography variant="body1">{comparison.unitCondition}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Unit With Fit Outs:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.unitWithFitOuts} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.unitWithFitOuts ? <Typography variant="body1">{comparison.unitWithFitOuts}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Furnished Rate:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.furnishedRate} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.furnishedRate ? <Typography variant="body1">{comparison.furnishedRate}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Rent Free Period:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.rentFreePeriod} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.rentFreePeriod ? <Typography variant="body1">{comparison.rentFreePeriod}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Landlord Lockin:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.landlordLockIn} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.landlordLockIn ? <Typography variant="body1">{comparison.landlordLockIn}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Tenant Lockin:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.tenantLockIn} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.tenantLockIn ? <Typography variant="body1">{comparison.tenantLockIn}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Security Deposit:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.securityDeposit} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.securityDeposit ? <Typography variant="body1">{comparison.securityDeposit}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Security Deposit Equivalent:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.securityDepositEquivalent} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.securityDepositEquivalent ? <Typography variant="body1">{comparison.securityDepositEquivalent}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Market Value:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.marketValue} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.marketValue ? <Typography variant="body1">{comparison.marketValue}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Stamp Duty Amount:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.stampDutyAmount} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.stampDutyAmount ? <Typography variant="body1">{comparison.stampDutyAmount}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Registration Amount:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.registrationAmount} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.registrationAmount ? <Typography variant="body1">{comparison.registrationAmount}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Car Parking Slots:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.carParkingSlots} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.carParkingSlots ? <Typography variant="body1">{comparison.carParkingSlots}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Car Parking Type:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.carParkingType} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.carParkingType ? <Typography variant="body1">{comparison.carParkingType}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Additional Car Parking Charge:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.carParkingCharge} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.carParkingCharge ? <Typography variant="body1">{comparison.carParkingCharge}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Two Wheeler Slots:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.twoWheelerSlots} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.twoWheelerSlots ? <Typography variant="body1">{comparison.twoWheelerSlots}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Two Wheeler Charge:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.twoWheelerCharge} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.twoWheelerCharge ? <Typography variant="body1">{comparison.twoWheelerCharge}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Monthly Cam Charge:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.monthlyCamCharge} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.monthlyCamCharge ? <Typography variant="body1">{comparison.monthlyCamCharge}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Cam Charge:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.camCharge} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.camCharge ? <Typography variant="body1">{comparison.camCharge}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Cam Paid By:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.camPaidBy} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.camPaidBy ? <Typography variant="body1">{comparison.camPaidBy}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Total Property Tax:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.totalPropertyTax} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.totalPropertyTax ? <Typography variant="body1">{comparison.totalPropertyTax}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Property Tax:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.propertyTax} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.propertyTax ? <Typography variant="body1">{comparison.propertyTax}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Property Tax Paid By:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.propertyTaxPaidBy} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.propertyTaxPaidBy ? <Typography variant="body1">{comparison.propertyTaxPaidBy}</Typography> : <NotFoundText />}
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Floor Plan:</Typography>
                </Grid2>
                <Grid2 size={4}>
                    <FieldDisplay field={data.floorPlan} />
                </Grid2>
                <Grid2 size={4}>
                    {comparison.floorPlan ? <Typography variant="body1">{comparison.floorPlan}</Typography> : <NotFoundText />}
                </Grid2>
            </Grid2>
        </Box>
    );
}