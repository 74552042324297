import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { initializeApp } from "firebase/app"
import { pdfjs } from "react-pdf";

const url = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();
console.log(url);
pdfjs.GlobalWorkerOptions.workerSrc = url;

const firebaseConfig = {
  apiKey: "AIzaSyCNXbNytLA6LgvV6iQP0gn79jkINsVWSWw",
  authDomain: "propayaprod.firebaseapp.com",
  projectId: "propayaprod",
  storageBucket: "propayaprod.appspot.com",
  messagingSenderId: "543255005631",
  appId: "1:543255005631:web:fc7c80b03920714fb8790a",
  measurementId: "G-J4FR4LGBR9"
};

initializeApp(firebaseConfig);

const root = createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <App />
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
