import { Box, Button, ButtonGroup, Grid2, IconButton, Switch, Tooltip, Typography, useTheme } from "@mui/material";
import { AnalysisResult, Source } from "../lib/interfaces";
import { Download, Replay } from "@mui/icons-material";
import colors from "../lib/colors";
import { useNavigate } from 'react-router';
import { NotFoundText } from "./FieldDisplay";
import { FieldDisplay } from "./FieldDisplay";

interface AnalysisDataDisplayProps {
    data: AnalysisResult,
    handleNewAnalysis: () => void,
    sourceHighlighting: boolean,
    handleSourceHighlightingChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}


export function AnalysisDataDisplay({ data, handleNewAnalysis, sourceHighlighting, handleSourceHighlightingChange }: AnalysisDataDisplayProps) {
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} overflow={"auto"} margin={theme.spacing(2)} borderRadius={theme.spacing(4)} bgcolor={"white"} padding={theme.spacing(4)} width={"85%"}>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"} marginBottom={theme.spacing(0)} width={"100%"}>
                <Box flex={1} />
                <Typography flex={1} textAlign={"center"} variant="h4">Lease Analysis</Typography>
                <Box flex={1} display={"flex"} flexDirection={"row"} alignItems={"right"} justifyContent={"flex-end"}>
                    <Tooltip title="Download Form">
                        <IconButton
                            href={data.reportSASURL}
                            target="_blank"
                        >
                            <Download
                                sx={{ color: colors.PropayaViolet }}
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="New Analysis">
                        <IconButton onClick={handleNewAnalysis}>
                            <Replay
                                sx={{ color: colors.PropayaViolet }}
                            />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Box
                sx={{
                    // position: "sticky",
                    borderRadius: "5px",
                    // top: theme.spacing(2),
                    // zIndex: 1000,
                    // boxShadow: 4,
                }}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                bgcolor={"white"}
                paddingLeft={theme.spacing(2)}
                width={"fit-content"}
                marginLeft={"auto"}
            >
                <Typography>Show Source Highlights</Typography>
                <Switch
                    checked={sourceHighlighting}
                    onChange={handleSourceHighlightingChange}
                />
            </Box>
            <Grid2 container spacing={2} alignItems="center">
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Document Number:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.documentNumber} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Project Name:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.projectName} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Plot Number:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.plotNumber} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">District:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.district} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Village:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.village} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Khewat:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.khewat} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Municipality:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.municipality} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Registrar Office:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.registrarOffice} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Agreement Type:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.agreementType} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Registration Date:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.registrationDate} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Landlord:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.landlord} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Landlord Representative:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.landlordRep} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Landlord Representative Role:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.landlordRepRole} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Landlord Email:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.landlordEmail} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Landlord Phone:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.landlordPhone} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Tenant:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.tenant} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Tenant Representative:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.tenantRep} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Tenant Representative Role:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.tenantRepRole} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Tenant Email:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.tenantEmail} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Tenant Phone:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.tenantPhone} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Unit Number:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.unit} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Floor Number:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.floor} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Wing:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.wing} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Other Unit Info:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.addressExtraDiscriminator} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Chargeable Area Type:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.chargeableAreaType} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Super Builtup Area:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.superBuiltupArea} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">BuiltUp Area:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.builtUpArea} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Carpet Area:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.carpetArea} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Agreement Start Date:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.agreementStartDate} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Agreement Expiration Date:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.agreementExpirationDate} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Agreement Duration:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.agreementDuration} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Monthly Rent:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.rentMonthly} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Monthly Square Foot Rent:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.rentPerSquareFootMonthly} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Consideration Value:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.considerationValue} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Escalation Terms:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.escalationTerms} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Unit Condition:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.unitCondition} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Unit With Fit Outs:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.unitWithFitOuts} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Furnished Rate:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.furnishedRate} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Rent Free Period:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.rentFreePeriod} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Landlord Lockin:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.landlordLockIn} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Tenant Lockin:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.tenantLockIn} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Security Deposit:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.securityDeposit} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Security Deposit Equivalent:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.securityDepositEquivalent} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Market Value:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.marketValue} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Stamp Duty Amount:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.stampDutyAmount} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Registration Amount:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.registrationAmount} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Car Parking Slots:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.carParkingSlots} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Car Parking Type:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.carParkingType} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Additional Car Parking Charge:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.carParkingCharge} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Two Wheeler Slots:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.twoWheelerSlots} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Two Wheeler Charge:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.twoWheelerCharge} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Monthly Cam Charge:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.monthlyCamCharge} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Cam Charge:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.camCharge} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Cam Paid By:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.camPaidBy} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Total Property Tax:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.totalPropertyTax} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Property Tax:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.propertyTax} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Property Tax Paid By:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.propertyTaxPaidBy} />
                </Grid2>
                <Grid2 size={4}>
                    <Typography variant="h6" fontWeight="bold">Floor Plan:</Typography>
                </Grid2>
                <Grid2 size={8}>
                    <FieldDisplay field={data.floorPlan} />
                </Grid2>
            </Grid2>
        </Box>
    );
}